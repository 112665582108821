import React, { useEffect, useState } from "react";
import Header2 from "../components/Layout/Header2";
import Footer2 from "../components/Layout/Footer2";

// Style
import "../styles/pages/propertyLandingPage.scss";

import STATIC from "../utils/stateAreaData";
import seoComponent from "../businesses/seoComponent";
import propertyLanding from "../businesses/propertyLandingComponent";

const PropertyLandingPage = () => {
  const [states, setStates] = useState([]);

  const fetchData = async () => {
    const activeStates = await propertyLanding.activeState();

    setStates(activeStates.data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Header2 />
      <section className="agentPage-empty2 container-fluid" />
      <section className="container state-gallery-container">
        <h4>States in Malaysia</h4>
        <div className="state-gallery">
          {states.map((state, index) => (
            <a
              key={index}
              href={`/properties/${seoComponent.stateToSeo(
                seoComponent.normalizeState(state)
              )}`}
              className="state-container state-gallery-item"
            >
              <div className="state-container-text">
                {state.replace("Wilayah Persekutuan", "")}
              </div>
              <img loading="lazy" src={STATIC.landing[state]} />
            </a>
          ))}
        </div>
      </section>
      <Footer2 />
    </>
  );
};

export default PropertyLandingPage;
