const isValidEmail = (email) => {
  const emailRegexp = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
  return emailRegexp.test(email);
};

const isAlphabet = (input) => {
  return /^[a-zA-Z ]+$/.test(input);
};

const isAlphanumeric = (input) => {
  return /^([a-zA-Z0-9 _-]+)$/.test(input);
};

const isValidMobile = (phone) => { //only accept +601X-XXXXXXXX / 01X-XXXXXXXX, dash is optional
  const msiaPhonePattern1 = /^((\+6)?01)[0|2|3|4|6|7|8|9]\-*[0-9]{7}$/ //other phone can only have 7 digits after dash
  const msiaPhonePattern2 = /^((\+6)?01)[1]\-*[0-9]{8}$/ //phone start with 011 can only have 8 digits after dash
  const isValid = (msiaPhonePattern1.test(phone) || msiaPhonePattern2.test(phone));
  return isValid;
};

// const isValidMsiaPhone = (phoneNumber) => { //for mobile input with country code selection use
//   //only accept 01X-XXXXXXXX / 1X-XXXXXXXX, dash is optional
//   const MsiaPhonePattern = /^(0?1)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,8}$/
//   return MsiaPhonePattern.test(phoneNumber);
// };

const isValidSGPhone = (phoneNumber) => {
  //any SG phone num starts with +65 or 65
  const SGPhonePattern = /^(\+?65)[0-9]{8}$/
  return SGPhonePattern.test(phoneNumber)
}

const isValidPhoneNumber = (phoneNumber) => {
  if(isValidMobile(phoneNumber) || isValidSGPhone(phoneNumber)) return true;
  return false;
}

const isNumeric = (input) => {
  //return (/^[0-9()-.\s]+$/.test(input));
  return /^[0-9]*$/.test(input);
};

// const isValidPhone = (phone) => {
//   if (phone.startsWith("+"))
//     return isNumeric(phone.substr(1)) && phone.length >= 10;
//   return isNumeric(phone) && phone.length >= 10;
// };

const isValidPostcode = (postcode) => {
  return isNumeric(postcode) && postcode.length == 5;
}

const isCompleteAddress = (address) => {
  if (
    !address.line1 ||
    address.city === "City" ||
    address.state === "State" ||
    !address.postcode ||
    address.country === "Country"
  )
    return false;
  else return true;
};

const isDecimal = (input) => {
  return /^[0-9]+(\.[0-9]{1,2})?$/.test(input);
};

const isURL = (input) => {
  return /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
    input
  );
};

const toVideoEmbedLink = (url) => {
  var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = url.match(regExp);

  if (match && match[2].length == 11) {
    return "https://www.youtube.com/embed/" + match[2] + "?autoplay=1";
  } else {
    return "";
  }
};

const isMobile = () => {
  return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export default {
  isValidEmail: isValidEmail,
  isAlphabet: isAlphabet,
  isAlphanumeric: isAlphanumeric,
  isValidMobile: isValidMobile,
  isValidPhoneNumber: isValidPhoneNumber,
  isNumeric: isNumeric,
  // isValidPhone: isValidPhone,
  isValidPostcode: isValidPostcode,
  isCompleteAddress: isCompleteAddress,
  isDecimal: isDecimal,
  isURL: isURL,
  toVideoEmbedLink: toVideoEmbedLink,
  isMobile: isMobile,
};
