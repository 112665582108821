import string from "../businesses/string";

const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

const isMongoDbObjectId = (s) => {
    return new RegExp('^[0-9a-fA-F]{24}$').test(s);
};

const getYoutubeId = (url) => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return "https://img.youtube.com/vi/" + match[2] + "/hqdefault.jpg"
    }
};

const formatDate = (datetime) => {
    const datetimeValue = new Date(datetime);
    return `${datetimeValue.toLocaleDateString()}`;
};

const formatDateMDY = (date) => { //January 11, 2023
    const dateValue = new Date(date).toLocaleString('en-us', { day: 'numeric', month: 'long', year: 'numeric' });
    return `${dateValue}`;
}

const formatDateTime = (datetime) => {
    const datetimeValue = new Date(datetime);
    //return datetimeValue.toLocaleString();

    let hours = datetimeValue.getHours();
    let minutes = datetimeValue.getMinutes();
    const apm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${datetimeValue.toLocaleDateString()} ${hours}:${minutes} ${apm}`;
};

const parseToLocale = datetime => {
    const datetimeValue = new Date(datetime);
    // console.log(datetimeValue.getFullYear());
    // console.log(datetimeValue.getMonth());
    // console.log(datetimeValue.getDate());
    // console.log(datetimeValue.getHours() + ':00:00');
    // console.log('------- ');

    let month = datetimeValue.getMonth() + 1;
    if (month < 10) month = '0' + month;
    let date = datetimeValue.getDate();
    if (date < 10) date = '0' + date;
    let hour = datetimeValue.getHours()
    if (hour < 10) hour = '0' + hour;
    hour += ':00:00';
    return {
        datePart: `${datetimeValue.getFullYear()}-${month}-${date}`,
        timePart: hour
    };
}

const addressPreOptions = (countries, address) => {
    let preCountry = (address.country) || '';
    let preState = (address.state) || '';
    let preArea = (address.area) || '';
    let defaultOptions = [];
    const defaultCountry = { id: 'country', value: 'Country' };
    const defaultState = { id: 'state', value: 'State' };
    const defaultArea = { id: 'area', value: 'Area' };

    const selectedCountry = countries.filter(c => c.country === preCountry);
    if (selectedCountry.length === 0) {
        defaultOptions.push(defaultCountry);
        defaultOptions.push(defaultState);
        defaultOptions.push(defaultArea);
        return defaultOptions;
    }

    const selectedState = selectedCountry[0].states.filter(s => s.state === preState);
    if (selectedState.length === 0) {
        defaultOptions.push(defaultState);
        defaultOptions.push(defaultArea);
        return defaultOptions;
    }

    const selectedArea = selectedState[0].areas.filter(area => area === preArea);
    if (selectedArea.length === 0) {
        defaultOptions.push(defaultArea);
        return defaultOptions;
    }

    return defaultOptions;
};

const categoryOptions = (categories) => {
    const categoryList = categories.map(c => c.category);
    return categoryList;
};

const typeOptions = (categories, category) => {
    let typeList = [];
    const selectedCategories = categories.filter(c => c.category === category);
    if (selectedCategories.length > 0) {
        typeList = selectedCategories[0].types
    }
    return typeList;
};

const countryOptions = (countries) => {
    const countryList = countries.map(c => c.country);
    return countryList;
};

const stateOptions = (countries, country) => {
    let statesList = [];
    const selectedCountry = countries.filter(c => c.country === country);
    if (selectedCountry.length > 0) {
        statesList = selectedCountry[0].states.map(s => s.state);
    }
    return statesList;
};

const areaOptions = (countries, country, state) => {
    let final = {};
    let areaList = [];
    const selectedCountry = countries.filter(c => c.country === country);
    if (selectedCountry.length > 0) {
        const selectedState = selectedCountry[0].states.filter(s => s.state === state);
        if (selectedState.length > 0) {
            areaList = selectedState[0].areas;
        }
    }
    else {
        final.states = [];
    }
    final.areas = areaList;
    return final;
};

//Version 2 of state & area options from n6agentverse (new agent portal)
const stateOptionsV2 = (stateArea) => {
    const statesList = stateArea.map(({ state }) => state);
    return statesList;
};

const areaOptionsV2 = (countryStates, state) => {
    let areaList = [];
    const selectedState = countryStates.filter(s => s.state === state);
    if (selectedState.length > 0) {
        areaList = selectedState[0].areas;
    }
    return areaList;
};

const displayLoc = loc => {
    if (loc && loc.coordinates) return `[${loc.coordinates[1]},${loc.coordinates[0]}]`;
    return `[0,0]`;
};

const currencyFormatter = (num) => {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num;
}

const currencyFormatter2Digits = (num) => {
    //remove .00 or last digit 0 for eg 1.70 = 1.7, 10.00 = 10, 1.75 = 1.75
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(2).replace(/\.00$|0$/, '') + 'G';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(2).replace(/\.00$|0$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(2).replace(/\.00$|0$/, '') + 'K';
    }
    return num;
}

const log = (val) => {
    if (process.env.REACT_APP_DEV_MODE === `true`)
        console.log(val);
};

const toTranslateKey = (value) => {
    return value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');
};

const toHyphen = (val) => {
    return val?.toString().toLowerCase()
        .replace(/[^A-Za-z0-9\-]/g, '-') //replace all non alphanumeric and not dash char to dash
        .replace(/\-+/g, '-') //replace all multi dash to single dash
        .replace(/^\-/g, '') //remove first dash
        .replace(/\-$/g, ''); //remove last dash since added during url combination
    // .replace(/[~!@#$%^&*()_+{}|:”<>?`=[\]\\;',.]/g, '')
    // .replace(/\s+/g, '-');
    // .split('.').join('-')
    // .split(' ').join('-')
    // .split('/').join('-')
    // .split(',').join('')
    // .split('#').join('')
    // .split('+').join('')
    // .split('%').join('')
    // .split('[').join('')
    // .split(']').join('')
    // .split('!').join('')
}

const addDashToPhoneNumber = (phoneNumber) => { //format phone number to 01X-XXXXXXX or 01X-XXXXXXXX before pass to backend
    // Remove '6' or '+6' from the beginning of the phone number
    phoneNumber = phoneNumber.replace(/^(\+?6)/, '');

    // Remove any existing dashes from the remaining phone number
    phoneNumber = phoneNumber.replace(/-/g, '');

    // Check if the phone number starts with "0XX" and add a dash accordingly
    if (phoneNumber.startsWith('0')) {
        if (phoneNumber.length >= 8) {
            return phoneNumber.substring(0, 3) + '-' + phoneNumber.substring(3);
        }
    }

    // If no dash added, return the original phone number
    return phoneNumber;
};

const combineMobileWithCountryCode = (countryCode, phoneNumber) => {
    if (countryCode === "+60") {
        phoneNumber = phoneNumber.replace(/^0/, ''); //remove first 0 if exists in Msia mobile
    }
    const combinedMobile = `${countryCode}${phoneNumber}`;

    return combinedMobile;
}

const getSorting = (label) => {
    // standardize by decoding label
    if (label === "default" || label === "Default") label = "";
    else if (label === "price-high_low") label = "Price High-Low";
    else if (label === "price-low_high") label = "Price Low-High";
    else if (label === "built_up-size-high_low") label = "Built-up Size High-Low";
    else if (label === "built_up-size-low_high") label = "Built-up Size Low-High";

    let order = 1;
    let column = "";
    if (label === "Price High-Low" || label === "Price Low-High") {
        column = "price";
        if (label === "Price High-Low") order = -1;
    } else if (label === "Built-up Size High-Low" || label === "Built-up Size Low-High") {
        column = "buildup";
        if (label === "Built-up Size High-Low") order = -1;
    }

    const sortObj = {
        label,
        order,
        column,
    }
    return sortObj;
}

export default {
    toTitleCase: toTitleCase,
    isMongoDbObjectId: isMongoDbObjectId,
    getYoutubeId: getYoutubeId,
    formatDate: formatDate,
    formatDateMDY,
    formatDateTime: formatDateTime,
    parseToLocale: parseToLocale,
    addressPreOptions: addressPreOptions,
    categoryOptions: categoryOptions,
    typeOptions: typeOptions,
    countryOptions: countryOptions,
    stateOptions: stateOptions,
    areaOptions: areaOptions,
    stateOptionsV2: stateOptionsV2,
    areaOptionsV2: areaOptionsV2,
    displayLoc: displayLoc,
    currencyFormatter: currencyFormatter,
    currencyFormatter2Digits,
    log: log,
    toTranslateKey: toTranslateKey,
    toHyphen: toHyphen,
    addDashToPhoneNumber: addDashToPhoneNumber,
    combineMobileWithCountryCode: combineMobileWithCountryCode,
    getSorting,
}