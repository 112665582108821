import React, { useEffect } from "react";
import AgentDetailPage from "./pages/agentDetailPage";
import AgentSearchPage from "./pages/agentSearchPage";
import PropertyDetailPage from "./pages/propertyDetailPage";
import ProjectDetailPage from "./pages/projectDetailPage";
// import PropertyList from "./pages/propertyList";
import AuctionDetailPage from "./pages/auctionDetailPage";
import AuctionRequestPage from "./pages/AuctionRequestPage";
import WorkshopPage from "./pages/WorkshopPage";
import BusinessCard from "./pages/businessCard";
import AgentApplicationPage from "./pages/AgentApplicationPage";
import ThankYouPage from "./pages/thankYouPage";
import HomeRevamp from "./pages/homeRevamp";
import NotFoundPage from "./pages/NotFoundPage";
import ContactUsPage from "./pages/contactUsPage";
import MortgageCalculatorPage from "./pages/MortgageCalculatorPage";
import AgentSignUpPage from "./pages/AgentSignUpPage";
import MerchantSignUpPage from "./pages/MerchantSignUpPage";
import DeleteAccountPage from "./pages/DeleteAccountPage";
import SiteMapPage from "./pages/siteMapPage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import AboutUsPage from "./pages/aboutUsPage";
import './index.css'
import './App.css'
import PropertyListV2 from "./pages/propertyListV2";
import { useDispatch } from "react-redux";
import { setFilterData } from "./redux/actions/seoDataActions";
import propertyComponent from "./businesses/property";
import PropertyLandingPage from "./pages/propertyLandingPage";
import PropertyLandingDetailPage from "./pages/propertyLandingDetailPage";

//NOTE: Please update the routes in "public/seo.js" > validRoutes f(x) if the 1st part of the url (/part1/part2/part3) is not added
//eg: "/agent/:id", 1st part of url = agent
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchFilterData = async () => { //for property search filter data
      const filters = await propertyComponent.getFilter();
      dispatch(setFilterData(filters))
    }
    fetchFilterData();
  }, [])

  return (
    <>
      <Router>
        <Switch>
          <>
          <Route exact path="/agent/:id" component={AgentDetailPage} />
          <Route exact path="/agent/:state/:area/:type" component={AgentSearchPage} />
          {/* <Route exact path="/auctions/:id" component={AuctionDetailPage} />
          <Route exact path="/auction/:key" component={AuctionRequestPage} /> */}
          <Route exact path="/workshop/:id" component={WorkshopPage} />
          {/* all /businessCard/:id routes redirected to /businesscard/:id */}
          <Route exact path="/businesscard/:id" component={BusinessCard} />
          <Route exact path="/signup" component={AgentApplicationPage} />
          <Route exact path="/signup/:tag" component={AgentSignUpPage} />
          <Route exact path="/business" component={MerchantSignUpPage} />
          <Route exact path="/thankyou" component={ThankYouPage} />
          <Route exact path="/" component={HomeRevamp} />


          {/* <Route exact path="/properties-for-rent/:malaysia_or_state" component={PropertyList} />
          <Route exact path="/properties-for-rent/:malaysia_or_state/:title_or_type_or_area" component={PropertyList} />
        <Route exact path="/properties-for-rent/:state/:area/:title_or_type" component={PropertyList} /> */}

          {/* <Route exact path="/projects-for-sale/:malaysia_or_state" component={PropertyList} />
          <Route exact path="/projects-for-sale/:state/:area" component={PropertyList} /> */}
          {/* <Route exact path="/auction-properties-for-sale/:malaysia_or_state" component={PropertyList} />
          <Route exact path="/auction-properties-for-sale/:malaysia_or_state/:title_or_type" component={PropertyList} />
          <Route exact path="/auction-properties-for-sale/:state/:title_or_type" component={PropertyList} /> */}
        
          {/* <Route exact path="/properties-for-sale/:malaysia_or_state" component={PropertyList} />
          <Route exact path="/properties-for-sale/:malaysia_or_state/:title_or_type_or_area" component={PropertyList} />
          <Route exact path="/properties-for-sale/:state/:area/:title_or_type" component={PropertyList} /> */}
          
          <Route exact path="/projects-for-sale" component={PropertyListV2} />
          <Route exact path="/projects-for-sale/:state" component={PropertyListV2} />
          <Route exact path="/projects-for-sale/:state/:area" component={PropertyListV2} />

          {/* <Route exact path="/auction-properties-for-sale" component={PropertyListV2} />
          <Route exact path="/auction-properties-for-sale/:state_or_title_or_type" component={PropertyListV2} />
          <Route exact path="/auction-properties-for-sale/:state/:title_or_type" component={PropertyListV2} /> */}

          <Route exact path="/ai-properties-for-rent/:ai" component={PropertyListV2} />
          <Route exact path="/ai-properties-for-sale/:ai" component={PropertyListV2} />
          
          <Route exact path="/properties-for-sale" component={PropertyListV2} />
          <Route exact path="/properties-for-sale/:state_or_title_or_type" component={PropertyListV2} />
          <Route exact path="/properties-for-sale/:state/:area_or_title_or_type" component={PropertyListV2} />
          <Route exact path="/properties-for-sale/:state/:area/:title_or_type" component={PropertyListV2} />
          
          <Route exact path="/properties-for-rent" component={PropertyListV2} />
          <Route exact path="/properties-for-rent/:state_or_title_or_type" component={PropertyListV2} />
          <Route exact path="/properties-for-rent/:state/:area_or_title_or_type" component={PropertyListV2} />
          <Route exact path="/properties-for-rent/:state/:area/:title_or_type" component={PropertyListV2} />

          <Route exact path="/property-listing/:property_info" component={PropertyDetailPage} />
          <Route exact path="/project-listing/:project_info" component={ProjectDetailPage} />
          {/* <Route exact path="/auction-listing/:auction_info" component={AuctionDetailPage} /> */}

          <Route exact path="/agent/:name/:id" component={AgentDetailPage} />
          <Route exact path="/contact" component={ContactUsPage} />
          <Route exact path="/about" component={AboutUsPage} />
          <Route exact path="/mortgage-calculator" component={MortgageCalculatorPage} />
          <Route exact path="/delete-account" component={DeleteAccountPage} />
          <Route exact path="/error/404" component={NotFoundPage} />
          <Route exact path="/sitemap" component={SiteMapPage} />

          <Route exact path="/properties" component={PropertyLandingPage} />
          <Route exact path="/properties/:state" component={PropertyLandingDetailPage} />
          <Route exact path="/properties/:state/:area" component={PropertyLandingDetailPage} />
          </>
          {/* redirect all invalid pages to 404 pg */}
          <Redirect from="*" to='/error/404' />
        </Switch>
      </Router>
    </>
    //NOTE: Please update the routes in "public/seo.js" > validRoutes f(x) if the 1st part of the url (/part1/part2/part3) is not added
    //eg: "/agent/:id", 1st part of url = agent
  );
}
export default App;
