import React, { useState } from 'react';
// import moment from "moment";
// import lib from "../../businesses/lib";
import SetupData from "../../data/SetupData";
import house from "../../assets/img/housegrey.svg";
import houseUnitGrey from "../../assets/img/houseUnitGrey.svg";
// import car from "../../assets/img/cargrey.svg";
import shower from "../../assets/img/bathgrey.svg";
import bed from "../../assets/img/bedgrey.svg";
import {
  Card,
  CardImg,
} from "reactstrap";
// import Avatar from "../../components/Avatar";
import locIcon from "../../assets/img/pin_rounded_circle.svg";
import { useTranslation } from 'react-i18next';

const ProjectCard = (props) => {
  const { t } = useTranslation();
  const property = props.property;
  // const agent = property.agent;
  const [imageTimeStamp] = useState(new Date().getTime());

  const handleProjectPopulateData = () => {
    if (props.callback) props.callback(property, props.type);
  };

  return (
    <>
      <Card
        className="property-list-card-body property-list-project"
        style={{ cursor: "pointer" }}
        title={`View ${property.name}`}
        onClick={() => handleProjectPopulateData(property)}
      >
        <CardImg
          className="property-list-cardImg2"
          src={
            property.images && property.images.length > 0
              ? `${property.images[0]}?${imageTimeStamp}`
              : ""
          }
          alt={property.name}
        />
        <div className="property-list-card-row-body2 property-row-body-project2">
          <div className="property-row-tagGroup2">
            <div className="property-row-saleTag2-wrapper">
              <div className="property-row-saleTag2 new-project">
                {t('new-project')}
              </div>
            </div>
            <div className="property-row-devLogoTag2">
              {property.developer?.logo ? <img src={property.developer.logo} className="property-row-devLogoTag2-img" alt="" /> : <img src={SetupData.defaultHouse} className="property-row-devLogoTag2-img" alt="" />}
            </div>
          </div>
          <div className="property-row-locGroup2">
            <img src={locIcon} className="locIcon2" alt="" />
            <p className="property-row-locText2">{property.address.area}, {property.address.state}</p>
          </div>
          <div className='property-row-priceText2-wrapper'>
            {
              props.type === "properties" &&
              <>
                <h6>{t('from')} </h6>
                <p className="property-row-priceText2">
                  RM{parseFloat(property.price.min).toLocaleString()}
                </p>
              </>
            }
          </div>
          <h3 className="property-row-nameText2">
            {property.name}&nbsp;
          </h3>
          <div className="property-row-iconsGroup2-wrapper">
            {property.bed?.min && (
              <span className="property-row-iconsGroup2">
                <span className="property-row-iconText2">
                  {property.bed.min && property.bed.max && <><img src={bed} alt="" className="property-row-iconImg2" /><span>{property.bed.min + "-" + property.bed.max}</span></>}
                  {property.bed.min && !property.bed.max && <><img src={bed} alt="" className="property-row-iconImg2" /><span>{`${t('from')} ${property.bed.min}`}</span></>}
                </span>
              </span>
            )}
            {property.bath?.min && (
              <span className="property-row-iconsGroup2">             
                <span className="property-row-iconText2">
                  {property.bath.min && property.bath.max && <><img src={shower} alt="" className="property-row-iconImg2" /><span>{property.bath.min + "-" + property.bath.max}</span></>}
                  {property.bath.min && !property.bath.max && <><img src={shower} alt="" className="property-row-iconImg2" /><span>{`${t('from')} ${property.bath.min}`}</span></>}
                </span>
              </span>
            )}
            {property.buildup != 0 && property.buildup && (
              <span className="property-row-iconsGroup2">
                <img src={house} alt="" className="property-row-iconImg2" />
                <span className="property-row-iconText2">
                  {property.buildup.min && property.buildup.max&& ` ${property.buildup.min}-${property.buildup.max}`}
                  {property.buildup.min && !property.buildup.max&& `${t('from')} ${property.buildup.min} sqft`}
                  </span>
              </span>
            )}
            {/* houseUnitGrey */}
            {property.units != 0 && property.units && (
              <span className="property-row-iconsGroup2">
                <img src={houseUnitGrey} alt="" className="property-row-iconImg2" />
                <span className="property-row-iconText2">
                  {property.units}
                  </span>
              </span>
            )}
          </div>
        </div>
        {/* {property.agent && props.showAgent && (
          <>
            <div className="property-row-footer2">
              <div className="property-row-footerGroup2">
                <Avatar
                  className="property-row-footerAvatar2"
                  src={
                    property.agent.image
                      ? property.agent.image
                      : SetupData.profile.imgURL
                  }
                />
                <div className="property-row-footerAgentInfo2">
                  <span className="property-row-footerCompany2">{property.agent.companyName}</span>
                  <span className="property-row-footerAgentName2">{property.agent.nickname || property.agent.name}</span>
                </div>
              </div>
            </div>
          </>
        )} */}
      </Card>
    </>
  );
};

export default ProjectCard;