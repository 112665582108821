import React, { useState, useEffect, Fragment } from "react";
import agentComponent from "../businesses/agent";
import auctionPropertyComponent from "../businesses/auctionProperty";
import propertyComponent from "../businesses/property";
import lib from "../businesses/lib";
import classnames from "classnames";
import "react-rater/lib/react-rater.css";
import AgentInfoDetail from "../components/AgentInfoDetail";
import Header2 from "../components/Layout/Header2";
import Footer2 from "../components/Layout/Footer2";
import {
    Row,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
    Modal
} from "reactstrap";
import { useParams, useHistory, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { trackPromise } from "react-promise-tracker";
import LoadingIndicator from "../components/LoadingIndicator";
import seoComponent from "../businesses/seoComponent";
import PageError from "../components/Layout/PageError";
import PropertyCard from "../components/Layout/PropertyCard";
import GoogleMapReact from "google-map-react";
import backToSearch from "../assets/img/back_to_search.svg"
import building from "../assets/img/newbuilding.svg"
import pointer from "../assets/img/newpointer.svg"
import "../styles/pages/agentDetailPage.scss"
import { useTranslation } from 'react-i18next';
import nextsixAppEmblem from "../assets/home/nextsix-app-emblem.png";
import { MdClose } from "react-icons/md";
import { close } from "../redux/actions/emblemActions";
import { useSelector, useDispatch } from 'react-redux';
import agentMapPreview from "../assets/img/agent-map-preview.png";
import biUserEngagementComponent from "../businesses/biUserEngagementComponent";
import gps from "../assets/img/locationgrey.svg";
import house from "../assets/img/housegrey.svg";
import car from "../assets/img/cargrey.svg";
import shower from "../assets/img/bathgrey.svg";
import bed from "../assets/img/bedgrey.svg";
import land from "../assets/img/icon_land_grey.svg";
import calendar from "../assets/agentSearch/whiteCalendar.svg";
import Avatar from "../components/Avatar";
import phone from "../assets/agentSearch/greenPhone.svg";
import style from '../styles/pages/agentSearchPage.module.scss';
import SetupData from "../data/SetupData";
import moment from "moment";
import ReactDOMServer from 'react-dom/server';
import infoIconFlag from "../assets/agentSearch/whiteInfoIcon.svg";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import string from "../businesses/string";
import CustomBreadcrumb from "../components/Breadcrumb";


const AgentDetailPage = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const emblem = useSelector(state => state.emblem);
    const getwidth = window.innerWidth;
    const { t } = useTranslation();
    let { id } = useParams();
    const MODE_SEARCH = "search";
    const [mode, setMode] = useState(MODE_SEARCH);
    const [agent, setAgent] = useState([]);
    const [buyProperties, setBuyProperties] = useState([]);
    const [rentProperties, setRentProperties] = useState([]);
    const [totalBuyRent, setTotalBuyRent] = useState({});
    const [activeTab, setActiveTab] = useState("Buy");
    const [totalAuctions, setTotalAuctions] = useState(0);
    const [auctionProperties, setAuctionProperties] = useState([]);
    const [auctionLoadMore, setAuctionLoadMore] = useState(false);
    const [page, setPage] = useState(1);
    const [rentPage, setRentPage] = useState(1);
    const [pageSummary, setPageSummary] = useState({});
    const [rentPageSummary, setRentPageSummary] = useState({});

    const [defaultZoom, setDefaultZoom] = useState(8);
    const [displayMap, setDisplayMap] = useState(false)

    const [defaultLocation, setDefaultLocation] = useState({
        //The Roof Realty Sdn Bhd  
        // lat: 3.085849,
        // lng: 101.692614,
        //Malaysia
        lat: 4.2105,
        lng: 101.9758,
    });
    const [infoCenterModal, setInfoCenterModal] = useState(false);
    const [modalPropertyStack, setModalPropertyStack] = useState([])

    const location = useLocation();

    const detectSource = () => {
        let fromSource = localStorage.getItem('from');
        console.log(fromSource)

        if (fromSource && id) {

            if (fromSource == "listing") {
                biUserEngagementComponent.biUserEngagement(fromSource, "agent", id)
                //  console.log("success list")
            }
            else if (fromSource == "map") {
                biUserEngagementComponent.biUserEngagement(fromSource, "agent", id)
                //  console.log("success map")
            }
        }
        else if (fromSource == "" && id) {
            biUserEngagementComponent.biUserEngagement("shared", "agent", id)
            //  console.log("success shared")
        }
        return localStorage.setItem('from', "");

    }

    useEffect(() => {
        //NXT-1876
        if (!lib.isMongoDbObjectId(id)) return history.push('/error/404');
        // if (!lib.isMongoDbObjectId(id)) return setMode("notFound");
        fetchData();
        performSearch();
        performRentSearch();
        performAuctionSearch();
    }, []);

    useEffect(() => {
        if (buyProperties) {
            if (buyProperties[0] && buyProperties[0].loc && buyProperties[0].loc.coordinates) {
                setDefaultLocation({ lat: buyProperties[0].loc.coordinates[1], lng: buyProperties[0].loc.coordinates[0] })
                setDefaultZoom(12)
            }
            else if (buyProperties[1] && buyProperties[1].loc && buyProperties[1].loc.coordinates) {
                setDefaultLocation({ lat: buyProperties[1].loc.coordinates[1], lng: buyProperties[1].loc.coordinates[0] })
                setDefaultZoom(12)
            }
        }
    }, [buyProperties])

    const fetchData = async () => {
        //NXT-1876
        const agent = await agentComponent.getAgentInfo(id, true);
        if (agent) setAgent(agent);
        else return history.push('/error/404');

        // const agent = await agentComponent.getAgentInfo(id, true);
        // agent ? setAgent(agent) : setMode("notFound");

        biUserEngagementComponent.biAgentLead(agent._id);
        setAgent(agent);
        console.log(agent)
        if (agent.active != "In Service") {
            let urlstate = null
            let urlarea = null
            let urltype = null

            const treatState = state => {
                return state.toLowerCase().trim().replace(` `, `-`);
            }

            const treatArea = area => {
                if (area === 'Layang-Layang') return 'layang-layang';
                else if (area === 'I-City') return 'i-city';
                else if (area === 'Bukit Tunku (Kenny Hills)') return 'bukit-tunku-kenny-hills';
                else if (area === 'Kampung Kerinchi (Bangsar South)') return 'kampung-kerinchi-bangsar-south';
                else if (area === 'Iskandar Puteri (Nusajaya)') return 'islandar-puteri-nusajaya';
                else if (area === 'Pulau Indah (Pulau Lumut)') return 'pulau-indah-pulau-lumut';
                return area.replace('(', '').replace(')', '').replaceAll(' ', '-').toLowerCase();
            }


            if (agent.area && agent.state) {
                urlarea = treatArea(agent.area)
                urlstate = treatState(agent.state)
            }

            if (agent.type) {

                urltype = agent.type.toString().toLowerCase()
                    .split('-').join('_')
                    .split('/').join('|')
                    .split(' ').join('-');
            }
            if (urlarea && urlstate && urltype)

                window.location = `/agent/${urlstate}/${urlarea}/${urltype}`
        }
        detectSource();
    };

    const performSearch = async () => {
        const totalBuyRent = await trackPromise(propertyComponent.totalBuyRent(id));
        setTotalBuyRent(totalBuyRent);
        const result = await trackPromise(propertyComponent.search(id, { active: "Active", for: "Buy" }, { page: 0 }));
        if (result) {
            setPageSummary(result.summary);
            setBuyProperties(result.data);
            setPage(1);
        }
        const resultRent = await trackPromise(propertyComponent.search(id, { active: "Active", for: "Rent" }, { page: 0 }));
        if (resultRent) {
            setRentPageSummary(resultRent.summary);
            setRentProperties(resultRent.data)
            setRentPage(1);
        }
    };

    const performRentSearch = async () => {
        const result = await trackPromise(propertyComponent.search(id, { active: "Active", for: "Rent" }, { page: 0 }));
        if (result) {
            setRentPageSummary(result.summary);
            setRentProperties(result.data)
            setRentPage(1);
        }
        console.log(result)
    };

    const performAuctionSearch = async () => {
        const res = await trackPromise(
            auctionPropertyComponent.search(id, "Approved", null, 0)
        );
        if (res) {
            let data = res.data;
            if (!(data && data.length === 0)) setAuctionLoadMore(true);
            const resCount = res.summary.record;
            setAuctionProperties(data);
            setTotalAuctions(resCount);
            if (data.length >= resCount) setAuctionLoadMore(false);
        }
    };

    useEffect(() => {
        if (page > 1) {
            const fetchMore = async () => {
                const result = await trackPromise(
                    propertyComponent.search(id, { active: "Active", for: "Buy" }, { page: page }));
                if (result && result.data)
                    setBuyProperties((prevProperties) => [...prevProperties, ...result.data]);
            };
            fetchMore();
        }
    }, [page]);

    useEffect(() => {
        if (rentPage > 1) {
            const fetchMore = async () => {
                const result = await trackPromise(
                    propertyComponent.search(id, { active: "Active", for: "Rent" }, { page: rentPage }));
                if (result && result.data)
                    setRentProperties((prevProperties) => [...prevProperties, ...result.data]);
            };
            fetchMore();
        }
    }, [rentPage]);


    const handleLoadMore = async () => {
        setPage((page) => page + 1);
    };

    const handleRentLoadMore = async () => {
        setRentPage((page) => page + 1);
    };

    const handleAuctionLoadMore = async () => {
        lib.log("Auction Load More");
        let newArr = auctionProperties.slice(0);
        const res = await trackPromise(
            auctionPropertyComponent.search(id, "Approved", null, auctionProperties.length)
        );
        if (res) {
            let data = res.data;
            data.forEach((element) => {
                newArr.push(element);
            });
        }
        setAuctionProperties(newArr);
        if (newArr.length < totalAuctions) setAuctionLoadMore(true);
        else setAuctionLoadMore(false);
    };

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handlePopulateData = (property, type) => {
        let newUrl;
        if (type === "auctions") {
            newUrl = seoComponent.createAuctionDetailUrl(property);
        } else {
            newUrl = seoComponent.createPropertyDetailUrl(property);
        }
        if (newUrl) return newUrl;
    };

    const BuyPropertyMarker = (props) => {
        const property = props.property;
        return (
            <div className="agentPage-propertyTab-plot2">
                RM {lib.currencyFormatter(property.buy.price)}
            </div>
        );
    };

    const RentPropertyMarker = (props) => {
        const property = props.property;
        return (
            <div className="agentPage-propertyTab-plot2">
                RM {lib.currencyFormatter(property.rent.monthlyRental)}
            </div>
        );
    };

    const AuctionMarker = (props) => {
        const auctions = props.auction
        return (
            <div className="agentPage-propertyTab-plotOuter2">
                <div className="agentPage-propertyTab-plot2">
                    RM{lib.currencyFormatter(auctions.price)}
                </div>
            </div>
        );
    };

    const goToAgentPage = (agent) => {
        const newUrl = seoComponent.createAgentDetailUrl(agent);
        return newUrl;
    };

    const handlePhone = (mobile) => {
        const phone = parsePhoneNumberFromString(mobile, "MY").nationalNumber;
        window.location = `tel:+60${phone}`;
    };

    const handleModalPopulateData = (property) => {
        if (property.for) {

            const newUrl = seoComponent.createPropertyDetailUrl(property);
            return newUrl;
        }
        else if (!property.for && property.referenceNo != "") {//for map info window auction cant if else inside

            const newUrl = seoComponent.createAuctionDetailUrl(property);
            return newUrl;
        }

    };

    const handleModalStack = (propertyStack) => {
        setModalPropertyStack(propertyStack)
        toggleInfoCenterModal()
        // console.log(propertyStack)
    }


    const toggleInfoCenterModal = () => {
        setInfoCenterModal(!infoCenterModal)
    }


    const ModelsMap = (map, maps) => {

        // lib.log(properties.length)
        let properties = []
        if (activeTab === "Auction" && auctionProperties) {
            properties = auctionProperties.slice(0, 30)
        }
        else if (activeTab === "Rent" && rentProperties) {
            properties = rentProperties.slice(0, 30)
        }
        else if (activeTab === "Buy" && buyProperties) {
            properties = buyProperties.slice(0, 30)
        }


        const groupPropertyStack = properties.reduce((tmp, x) => {
            // item to treat
            const match = tmp.find(y => y.some(z => (z.address.area === x.address.area && z.masterName === x.masterName && (z.masterName != null && x.masterName != null) && z.realestateMaster === x.realestateMaster && (z.realestateMaster != null && x.realestateMaster != null))));//to group all same area who stack//propertymaster

            // If we have such array, push the data into it//that stack
            if (match) {
                match.push(x);
            } else {
                // If we don't create a new array
                tmp.push([x]);
            }

            return tmp;
        }, []);


        if (groupPropertyStack) {

            // console.log(groupPropertyStack,"line 155")
            for (let i = 0; i < groupPropertyStack.length; i++) {
                if (groupPropertyStack[i].length >= 1) {
                    let propertyStack = groupPropertyStack[i]
                    if (propertyStack.length >= 2 && propertyStack[0].masterName) {//larger or equal to 2 that in same stacking && 
                        // console.log(propertyStack)
                        //this might use modal display [{},{},{}] no info window/ onlick set modal with scroll bar
                        const size = string.getWidth(propertyStack[0].masterName + " +" + propertyStack.length)
                        let marker = new maps.Marker({
                            position: {
                                lat: propertyStack[0].loc.coordinates[1],//cause stacking so took the 1st loc
                                lng: propertyStack[0].loc.coordinates[0],
                            },
                            map,
                            label: {
                                text: propertyStack[0].masterName + " +" + propertyStack.length,//propertyStack[0].masterName
                                color: "#ffffff",
                                fontWeight: "bold",
                                className: "property-list-Maplabel-infowindow"
                            },
                            // icon: {
                            //     path: maps.SymbolPath.BACKWARD_CLOSED_ARROW,//this only can click on center of the label//limitation//if use svg accuracy will run away while zooming
                            //     strokeColor: "#00A82A",
                            //     scale: 6,
                            //     strokeWeight: 3,
                            //     origin: new maps.Point(0, 0),
                            //     scaledSize: new maps.Size(20, 20)

                            // },
                            icon: {
                                url: 'https://maps.gstatic.com/mapfiles/transparent.png',
                                origin: new maps.Point(0, 0),
                                scaledSize: new maps.Size(size, 28)
                            }

                        });
                        marker.addListener("click", () => {
                            handleModalStack(propertyStack)
                        });
                    }

                    else {//stack have or no mastername (show 1st)neglect other/ only one  , *** one loc only allow one property
                        // console.log(propertyStack,"w/o master   or ( stack but 1st data on same loc dont have master)")//this normal display propertyStack[0]//will not display modal
                        if (propertyStack[0].loc) {
                            if (propertyStack[0]) {
                                let label = ""
                                let propertyFor = ""
                                let propertyRentType = ""
                                let price = ""
                                let datedisplay = ""

                                if (propertyStack[0].for && (propertyStack[0].for.toLowerCase() === "rent" || propertyStack[0].for.toLowerCase() === "buy")) {
                                    label = propertyStack[0].for.toLowerCase() === "rent" ? "RM " + lib.currencyFormatter(propertyStack[0].rent.monthlyRental)
                                        : "RM " + lib.currencyFormatter(propertyStack[0].buy.price)

                                    propertyFor = propertyStack[0].for.toLowerCase() === "buy" ? lib.toTitleCase("buy") : lib.toTitleCase("rent");

                                    propertyRentType = propertyStack[0].for.toLowerCase() === "rent" ? propertyStack[0].rentType || "" : "";

                                    price = propertyStack[0].for.toLowerCase() === "rent" ?
                                        `RM ${parseFloat(propertyStack[0].rent.monthlyRental).toLocaleString()}/month`
                                        : "RM " + parseFloat(propertyStack[0].buy.price).toLocaleString();
                                    datedisplay = propertyStack[0].updated ? propertyStack[0].updated : propertyStack[0].created
                                }
                                else {
                                    label = "RM " + lib.currencyFormatter(propertyStack[0].price)
                                    propertyFor = "Auction"
                                    price = "RM " + parseFloat(propertyStack[0].price).toLocaleString();
                                    datedisplay = propertyStack[0].auctionDate ? propertyStack[0].auctionDate : propertyStack[0].created
                                }

                                const propertyPng = propertyStack[0].images && propertyStack[0].images.length > 0 ? propertyStack[0].images[0] : SetupData.defaultHouse;
                                const addressArea = propertyStack[0].address.area ? propertyStack[0].address.area + "," : ""
                                const title = propertyStack[0].title ? propertyStack[0].title : "-";
                                const numOfBed = propertyStack[0].beds ? (propertyStack[0].beds !== "Bedrooms" && propertyStack[0].beds !== "Beds") ? propertyStack[0].beds : "-" : "-";
                                const numOfBath = propertyStack[0].baths ? propertyStack[0].baths : "-";
                                const numOfCarPark = propertyStack[0].carParks ? propertyStack[0].carParks : "-";
                                const numOfLandArea = propertyStack[0].landArea ? propertyStack[0].landArea : "-";
                                const numOfBuildUp = propertyStack[0].buildup ? propertyStack[0].buildup : "-";

                                let link = "https://google.com"
                                if (propertyStack[0].for) {
                                    link = seoComponent.createPropertyDetailUrl(propertyStack[0])
                                }//multiple if for diff secnario //local/sit/next six took from env or anyway
                                else if (!propertyStack[0].for && propertyStack[0].referenceNo !== "") {
                                    link = seoComponent.createAuctionDetailUrl(propertyStack[0])
                                }

                                const info = (
                                    <div>
                                        <a href={link} style={{ 'textDecoration': 'none' }} rel="noopener noreferrer" target="_blank" onClick={() => localStorage.setItem('from', "map")} >
                                            <div className='property-list-card'>
                                                <div className='property-map-card' >
                                                    <div>
                                                        <img loading='lazy' src={propertyPng} className='info-card-image' alt={propertyStack[0] && (propertyStack[0].for == "Rent" || propertyStack[0].for == "Buy") && (propertyStack[0].type + " " + (propertyStack[0].for == "Rent" ? "For Rent" : "For Sale") + " in " + propertyStack[0].address?.area + " " + propertyStack[0].address?.state.replace(`Wilayah Persekutuan`, ``).trim())} />
                                                    </div>
                                                    <br />
                                                    <div className='property-list-pricelabel' >
                                                        <div className='property-list-statuslabel-slide'>
                                                            <div className='property-list-statuslabel'>
                                                                {propertyFor}
                                                            </div>
                                                            {propertyRentType && <div className='property-list-statuslabel rent-type'>
                                                                {propertyRentType}
                                                            </div>}
                                                            <div className='property-list-date'>
                                                                <span className='lightgreytext'>
                                                                    {propertyFor === "Auction" ? <>{'Auction Date on'} {moment(datedisplay).format("DD/MM/YYYY")}</> : <>{'Last Updated on'} {moment(datedisplay).format("DD/MM/YYYY")}</>}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <p className='mt-2 mb-2 greytext property-list-address'>
                                                            <img loading='lazy' src={gps} className='locationlogo' alt="" />{addressArea}, {propertyStack[0].address.state}
                                                        </p>
                                                        <div className='property-list-price'>{price}</div>
                                                        <div className='property-list-card-name'><b >{propertyStack[0].name} </b></div><br />
                                                        <span className='property-card-facilitylogo-padding'>
                                                            <img loading='lazy' src={bed} className='facilitylogo' alt="" /><span className="facilitylogo-description">{numOfBed}</span>
                                                            <img loading='lazy' src={shower} className='facilitylogo' alt="" /><span className="facilitylogo-description">{numOfBath}</span>
                                                            <img loading='lazy' src={car} className='facilitylogo' alt="" /><span className="facilitylogo-description">{numOfCarPark}</span>
                                                            {(() => {
                                                                if (title === 'Agricultural Land' || title === 'Commercial Land'
                                                                    || title === 'Industrial Land' || title === 'Residential Land') {
                                                                    if (numOfLandArea) {
                                                                        return <><img loading='lazy' src={land} className='facilitylogo' alt="" /><span className="facilitylogo-description">{numOfLandArea}sqft</span></>
                                                                    }
                                                                } else {
                                                                    if (numOfBuildUp) {
                                                                        return <> <img loading='lazy' src={house} className='facilitylogo' alt="" /><span className="facilitylogo-description">{numOfBuildUp} sqft </span></>
                                                                    }
                                                                }
                                                            })()}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )

                                const infowindow = new maps.InfoWindow({
                                    content: ReactDOMServer.renderToString(info),
                                    maxWidth: 370,
                                    maxHeight: 600,
                                });

                                const size = string.getWidth(label)

                                let marker = new maps.Marker({
                                    position: {
                                        lat: propertyStack[0].loc.coordinates[1],
                                        lng: propertyStack[0].loc.coordinates[0],
                                    },
                                    map,
                                    label: {
                                        text: label,
                                        color: "#ffffff",
                                        fontWeight: "bold",
                                        className: "property-list-Maplabel-infowindow"
                                    },
                                    // icon: {
                                    //     path: maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                                    //     strokeColor: "#00A82A",
                                    //     scale: 6,
                                    //     strokeWeight: 3,
                                    //     origin: new maps.Point(0, 0),
                                    //     scaledSize: new maps.Size(20, 20)
                                    // },
                                    icon: {
                                        url: 'https://maps.gstatic.com/mapfiles/transparent.png',
                                        origin: new maps.Point(0, 0),
                                        scaledSize: new maps.Size(size, 28)
                                    }
                                });

                                marker.addListener("dblclick", () => {
                                    handlePopulateData(propertyStack[0], "map")
                                });

                                marker.addListener("click", () => {
                                    infowindow.open({
                                        anchor: marker,
                                        map,
                                        shouldFocus: false,
                                    });
                                    handlePopulateData(propertyStack[0], "map")
                                });

                                // marker.addListener("mouseout", () => {
                                //   infowindow.close({
                                //     anchor: marker,
                                //     map,
                                //     shouldFocus: false,
                                //   });
                                // })

                                // marker.addListener("mouseout", () => setTimeout(function () {
                                //   infowindow.close({
                                //     anchor: marker,
                                //     map,
                                //     shouldFocus: false,
                                //   })
                                // }, 2000))
                            }
                        }
                    }
                }
            }
        }
    };

    const UrlNav = (props) => {
      const { agent } = props;
      if (agent.area && agent.state) {
        const urlAgent = `/agent/all-states/all-areas/all-types`;
        const treatedState = `${agent.state?.toLowerCase().replace(/ /g, "-")}`;
        const urlState = `/agent/${treatedState}/all-areas/all-types`;
        const urlArea = `/agent/${treatedState}/${seoComponent.treatArea(
          agent.area
        )}/all-types`;

        const breadcrumbData = {
          home: {
            title: "Home",
            url: "/",
          },
          agents: {
            title: "Agents",
            url: urlAgent,
          },
          state: {
            title: agent.state,
            url: urlState,
          },
          area: {
            title: agent.area,
            url: urlArea,
          },
          currAgent: {
            title: agent?.nickname ?? agent.name,
            url: location.pathname,
          },
        };

        return <CustomBreadcrumb location={location} items={breadcrumbData} />;

        // return <span className="url-path2">
        //     <span className="url-path-next2">
        //         <a href="/">Home</a>{' > '}
        //         <a href={`${urlAgent}`}>Agents</a>{' > '}
        //         <a href={urlState}>{agent.state}</a>{' > '}
        //         <a href={urlArea}>{agent.area}</a>{' > '}
        //         {agent?.nickname ?? agent.name}
        //     </span>
        // </span>;
      }

      return null;
    }

    const agentInfo = (
        <Fragment>
            <Container fluid className="agentPage-empty2">
            </Container>
            <Container className="agentPage-container2">
                <div className="back-to-search2">
                    <span className="back-to-search-button2 back-to-search-span" onClick={() => window.history.back()}>
                        <span className="back-to-search-text2"><img loading='lazy' src={backToSearch} alt="" className="img-back-logo" />
                            {t('back')}
                        </span>
                    </span>

                    {/* <span className="url-path2">
                        {'Agent'} <span className="url-path-next2">{">"}</span> {agent.nickname ? agent.nickname : agent.name}
                    </span> */}

                    <UrlNav agent={agent} />
                </div>
                <div className="agentPage-info2">
                    <div className="agentPage-info-left2">
                        <AgentInfoDetail agents={agent} />
                        {agent && agent.about && (
                            <>
                                <div className="agentPage-aboutMe2">
                                    <div className="agentPage-aboutMeLeft2">
                                        <p className="agentPage-aboutMeTitle2">{t('about-me')}:</p>
                                        <p className="agentPage-aboutMeText2">{agent.about}</p>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="agentPage-info-right2">
                        <span className="agentPage-info-rightTitle2">{t('properties')}</span>
                        <div className="agentPage-info-rightCounter2">
                            <Col className="agentPage-info-rightCounterBox2 counterBoxBorder2">
                                <span className="agentPage-info-rightCount2">{totalBuyRent.buy}</span>
                                <span className="agentPage-info-rightText2">{t('for-sale')}</span>
                            </Col>
                            <Col className="agentPage-info-rightCounterBox2">
                                <span className="agentPage-info-rightCount2">{totalBuyRent.rent}</span>
                                <span className="agentPage-info-rightText2">{t('for-rent')}</span>
                            </Col>
                            {/* <Col className="agentPage-info-rightCounterBox2">
                                <span className="agentPage-info-rightCount2">{totalAuctions}</span>
                                <span className="agentPage-info-rightText2">{t('for-auction')}</span>
                            </Col> */}
                        </div>
                        {agent && agent.areaSpecialists && agent.areaSpecialists.length > 0 && (
                            <>
                                <div className="agentPage-aboutAS-block2">
                                    <p className="agentPage-aboutMeAS2">Area Specialist</p>
                                    {agent.areaSpecialists && agent.areaSpecialists.map((obj, i) => {
                                        return (

                                            <p className="agentPage-aboutMe-text2" key={i}><img loading='lazy' className="agentPage-aboutMe-textImg2" alt="" src={pointer} />{obj.area}</p>

                                        )
                                    })}
                                </div>
                            </>
                        )}
                        {agent && agent.titledSpecialists && agent.titledSpecialists.length > 0 && (
                            <>
                                <div className="agentPage-aboutTS-block2">
                                    <p className="agentPage-aboutMeTS2">Title Specialist</p>
                                    {agent.titledSpecialists && agent.titledSpecialists.map((obj, i) => {
                                        return (

                                            <p className="agentPage-aboutMe-text2" key={i}><img loading='lazy' className="agentPage-aboutMe-textImg2" alt="" src={building} />{obj.title}</p>

                                        )
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="agentPage-propertyTabMap2">
                    <Col className="agentPage-propertyTab2" lg={6} md={6} sx={12} xs={12}>
                        <div>
                            <Nav tabs className="agentPage-propertyTab-navBar2">
                                <NavItem className="agentPage-propertyTab-navItem2 counterBoxBorder2">
                                    <NavLink
                                        className={classnames({ active: activeTab === "Buy" })}
                                        onClick={() => toggle("Buy")}
                                        style={{ cursor: "pointer", borderRadius: "7px 0 0 7px" }}
                                    >
                                        {t('for-sale')}
                                        {/* {pageSummary.records} */}
                                    </NavLink>
                                </NavItem>
                                <NavItem className="agentPage-propertyTab-navItem2 counterBoxBorder2">
                                    <NavLink
                                        className={classnames({ active: activeTab === "Rent" })}
                                        onClick={() => {
                                            toggle("Rent");
                                        }}
                                        style={{ cursor: "pointer", borderRadius: "0 7px 7px 0" }}
                                    >
                                        {t('for-rent')}
                                        {/* {rentPageSummary.records} */}
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem className="agentPage-propertyTab-navItem2">
                                    <NavLink
                                        className={classnames({ active: activeTab === "Auction" })}
                                        onClick={() => {
                                            toggle("Auction");
                                        }}
                                        style={{ cursor: "pointer", borderRadius: "0 7px 7px 0" }}
                                    >
                                        {t('for-auction')}
                                    </NavLink>
                                </NavItem> */}
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="Buy">
                                    <Col sm="12" className="agentPage-propertyTab-wrapper">
                                        <div className="agentPage-propertyTab-infiniteScroll2 custom-infinite-scrollbar2" id="scrollableDiv">
                                            {buyProperties.length > 0 && (
                                                <InfiniteScroll
                                                    dataLength={buyProperties.length}
                                                    next={handleLoadMore}
                                                    hasMore={page < pageSummary.pages}
                                                    loader={<p className="text-muted text-center agentPage-loaderText">
                                                        <small> Loading... </small>
                                                    </p>}
                                                    endMessage={
                                                        <p className="text-muted text-center agentPage-loaderText">
                                                            <small>{t('reached-the-end')}</small>
                                                        </p>
                                                    }
                                                    scrollableTarget="scrollableDiv"
                                                    style={{ overflow: "hidden auto" }}
                                                >
                                                    <Row>
                                                        {buyProperties.map((property, idx) => {
                                                            return (
                                                                <Col key={idx} lg={6} md={12} xs={12} sm={12}>
                                                                    <a href={handlePopulateData(property)} style={{ textDecoration: "none", display: "contents" }} onClick={() => localStorage.setItem('from', "listing")}>
                                                                        <PropertyCard property={property} type="properties" loc="agentDetail" showAgent={false} />
                                                                    </a>
                                                                </Col>
                                                            );
                                                        })
                                                        }
                                                    </Row>
                                                </InfiniteScroll>
                                            )}
                                        </div>
                                    </Col>
                                </TabPane>
                                <TabPane tabId="Rent">
                                    <Col sm="12" className="agentPage-propertyTab-wrapper" >
                                        <div className="agentPage-propertyTab-infiniteScroll2 custom-infinite-scrollbar2" id="scrollableDiv2">
                                            {rentProperties.length > 0 && (
                                                <InfiniteScroll
                                                    dataLength={rentProperties.length}
                                                    next={handleRentLoadMore}
                                                    hasMore={rentPage < rentPageSummary.pages}
                                                    loader={<p className="text-muted text-center agentPage-loaderText">
                                                        <small> Loading... </small>
                                                    </p>}
                                                    endMessage={
                                                        <p className="text-muted text-center agentPage-loaderText">
                                                            <small>{t('reached-the-end')}</small>
                                                        </p>
                                                    }
                                                    scrollableTarget="scrollableDiv2"
                                                    style={{ overflow: "hidden auto" }}
                                                >
                                                    <Row>
                                                        {rentProperties.map((property, idx) => {
                                                            return (
                                                                <Col key={idx} lg={6} md={12} xs={12} sm={12}>
                                                                    <a href={handlePopulateData(property)} style={{ textDecoration: "none", display: "contents" }} onClick={() => localStorage.setItem('from', "listing")}>
                                                                        <PropertyCard property={property} type="properties" loc="agentDetail" showAgent={false} />
                                                                    </a>
                                                                </Col>
                                                            );
                                                        })
                                                        }
                                                    </Row>
                                                </InfiniteScroll>
                                            )}
                                        </div>
                                    </Col>
                                </TabPane>
                                <TabPane tabId="Auction">
                                    <Col sm="12" className="agentPage-propertyTab-wrapper">
                                        <div className="agentPage-propertyTab-infiniteScroll2 custom-infinite-scrollbar2" id="scrollableDiv3">
                                            {auctionProperties.length > 0 && (
                                                <InfiniteScroll
                                                    dataLength={auctionProperties.length}
                                                    next={handleAuctionLoadMore}
                                                    hasMore={auctionLoadMore}
                                                    loader={<p className="text-muted text-center agentPage-loaderText">
                                                        <small> Loading... </small>
                                                    </p>}
                                                    endMessage={
                                                        <p className="text-muted text-center agentPage-loaderText">
                                                            <small>{t('reached-the-end')}</small>
                                                        </p>
                                                    }
                                                    scrollableTarget="scrollableDiv3"
                                                    style={{ overflow: "hidden auto" }}
                                                >
                                                    <Row>
                                                        {auctionProperties.map((property, idx) => {
                                                            return (
                                                                <Col key={idx} lg={6} md={12} xs={12} sm={12} className="mb-3">
                                                                    <a href={handlePopulateData(property, "auctions")} style={{ textDecoration: "none" }} onClick={() => localStorage.setItem('from', "listing")}>
                                                                        <PropertyCard property={property} type="properties" showAgent={false} />
                                                                    </a>
                                                                </Col>
                                                            );
                                                        })
                                                        }
                                                    </Row>
                                                </InfiniteScroll>
                                            )}
                                        </div>
                                    </Col>
                                </TabPane>
                            </TabContent>
                        </div>
                    </Col>
                    <Col className="agentPage-propertyMap2" lg={6} md={6} sx={12} xs={12}>


                        {displayMap ?
                            <div className="agentPage-propertyMap-content2">
                                {/* <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: "AIzaSyCmNc38aqtguJ66jg0hL9XIAcCyiFY2AzU",
                                }}
                                center={defaultLocation}
                                zoom={defaultZoom}
                                options={{ gestureHandling: "greedy" }}
                            >
                                {activeTab === "Buy" && buyProperties.slice(0, 30).map((property, idx) => {
                                    return (
                                        property.loc &&
                                        <BuyPropertyMarker
                                            key={idx}
                                            lat={property.loc && property.loc.coordinates[1]}
                                            lng={property.loc && property.loc.coordinates[0]}
                                            property={property}
                                            index={idx}
                                        />
                                    );
                                })}
                                {activeTab === "Rent" && rentProperties.slice(0, 30).map((property, idx) => {
                                    return (
                                        property.loc &&
                                        <RentPropertyMarker
                                            key={idx}
                                            lat={property.loc && property.loc.coordinates[1]}
                                            lng={property.loc && property.loc.coordinates[0]}
                                            property={property}
                                            index={idx}
                                        />
                                    );
                                })}
                                {activeTab === "Auction" && auctionProperties.slice(0, 30).map((auction, idx) => {
                                    return (
                                        auction.loc &&
                                        <AuctionMarker
                                            key={idx}
                                            lat={auction.loc && auction.loc.coordinates[1]}
                                            lng={auction.loc && auction.loc.coordinates[0]}
                                            auction={auction}
                                            index={idx}
                                        />
                                    );
                                })}
                                
                            </GoogleMapReact> */}
                                {activeTab === "Buy" &&
                                    <GoogleMapReact
                                        bootstrapURLKeys={{
                                            key: process.env.REACT_APP_GOOGLE_API_KEY
                                        }}
                                        defaultCenter={defaultLocation}
                                        defaultZoom={defaultZoom}
                                        yesIWantToUseGoogleMapApiInternals//
                                        onGoogleApiLoaded={({ map, maps }) => { ModelsMap(map, maps) }}
                                        style={{ borderRadius: "15px" }}
                                        options={{ gestureHandling: "greedy" }}
                                    >
                                    </GoogleMapReact>
                                }
                                {activeTab === "Rent" &&
                                    <GoogleMapReact
                                        bootstrapURLKeys={{
                                            key: process.env.REACT_APP_GOOGLE_API_KEY
                                        }}
                                        defaultCenter={defaultLocation}
                                        defaultZoom={defaultZoom}
                                        yesIWantToUseGoogleMapApiInternals//
                                        onGoogleApiLoaded={({ map, maps }) => { ModelsMap(map, maps) }}
                                        style={{ borderRadius: "15px" }}
                                        options={{ gestureHandling: "greedy" }}
                                    >
                                    </GoogleMapReact>
                                }

                                {activeTab === "Auction" &&
                                    <GoogleMapReact
                                        bootstrapURLKeys={{
                                            key: process.env.REACT_APP_GOOGLE_API_KEY
                                        }}
                                        defaultCenter={defaultLocation}
                                        defaultZoom={defaultZoom}
                                        yesIWantToUseGoogleMapApiInternals//
                                        onGoogleApiLoaded={({ map, maps }) => { ModelsMap(map, maps) }}
                                        style={{ borderRadius: "15px" }}
                                        options={{ gestureHandling: "greedy" }}
                                    >
                                    </GoogleMapReact>
                                }
                            </div>
                            :
                            <div className="agentPage-propertyMap-content2">
                                <img loading='lazy' onClick={() => setDisplayMap(true)} src={agentMapPreview} className="agentPage-propertyMap-content2-map-preview"></img>
                            </div>
                        }
                    </Col>
                </div>
            </Container>
        </Fragment>
    );

    // useEffect(() => {
    //     dispatch(close("open"));
    // }, [])

    const closeBanner = () => {
        dispatch(close("close"));
    }

    return (
        <>
            {
                getwidth < 768 && emblem === "open" &&
                <div className="emblem-wrapper">
                    <div className="emblem-left-content">
                        <Button className="emblem-close-btn" onClick={() => closeBanner()}><MdClose /></Button>
                        <img loading='lazy' className="emblem-logo" src={nextsixAppEmblem} alt="" />
                        <div className="emblem-left-textbox">
                            <span className="emblem-left-text-top">NextSix App</span>
                            <span className="emblem-left-text-btm">Find properties and agents faster</span>
                        </div>
                    </div>
                    <div className="emblem-right-content">
                        <a className="emblem-download-btn" href="https://apps.nextsix.com/users/reg">Use Now</a>
                    </div>
                </div>
            }
            <Header2 className="otherPage" />
            <LoadingIndicator />
            {/* NXT-1876 */}
            {agentInfo}
            {/* {mode === MODE_SEARCH ? agentInfo : <PageError />} */}

            {modalPropertyStack && <Modal
                className={`${style.infoCenter_popup} agentSearch_flagModal`}
                isOpen={infoCenterModal}
                toggle={toggleInfoCenterModal}>
                <div className={`${style.mapBlock_infoCenterImg}`}>
                    <div className={`${style.mapBlock_infoCenter_header}`}>
                        <p className={`${style.mapBlock_infoCenter_area}`}>
                            {modalPropertyStack[0]?.masterName + " +" + modalPropertyStack.length}
                        </p>
                        <img loading='lazy'
                            className={`${style.mapBlock_infoCenter_infoIcon}`}
                            src={infoIconFlag}
                            alt="" />
                    </div>

                </div>
                <div className={`${style.infoCenter_popup_content}`}>
                    <div className={`${style.infoCenter_popup_close}`}><MdClose onClick={toggleInfoCenterModal} /></div>
                    <TabContent activeTab={"Property"} className={`${style.infoCenter_tabContent}`}>
                        <TabPane tabId="Property" className={`${style.infoCenter_tabContent_property} custom-infinite-scrollbar2`}>
                            {modalPropertyStack.map((property, i) => {
                                return (
                                    <div className={`${style.infoCenter_propCard}`} key={i}>
                                        <div className={`${style.propCard_top}`}>
                                            <div className={`${style.propCard_imgWrap}`}>
                                                <img loading='lazy' className={`${style.propCard_img}`} src={property.images[0]} alt={property && (property.for == "Rent" || property.for == "Buy") && (property.type + " " + (property.for == "Rent" ? "For Rent" : "For Sale") + " in " + property.address?.area + " " + property.address?.state.replace(`Wilayah Persekutuan`, ``).trim())} />
                                            </div>
                                            <div className={`${style.propCard_topContent}`}>
                                                <a href={handleModalPopulateData(property)} className={`${style.redirectLink}`} rel="noopener noreferrer" target="_blank" onClick={() => localStorage.setItem('from', "map")}>
                                                    <div className={`${style.propCard_topContent_innerWrap}`}>
                                                        <p className={`${style.propCard_name}`}>{property.name}</p>
                                                        <div>
                                                            <p className={`${style.propCard_for}`}>{property.for === "Buy" ? "Sale" : property.for}</p>
                                                            {property.for.toLowerCase() === "rent" ?
                                                                <p className={`${style.propCard_price}`}>
                                                                    {`RM ${parseFloat(property.rent.monthlyRental).toLocaleString()}/month`}
                                                                </p>
                                                                : <p className={`${style.propCard_price}`}>
                                                                    RM {parseFloat(property.buy.price).toLocaleString()}
                                                                </p>
                                                            }
                                                            <div className={`${style.propCard_topContent_iconGroup}`}>
                                                                <div>
                                                                    {property.beds != "Bedrooms" && property.beds && (
                                                                        <Fragment>
                                                                            <img loading='lazy' className={`${style.propCard_topContent_icon}`} src={bed} alt="" />
                                                                            <span className={`${style.propCard_topContent_iconText}`}>{property.beds}
                                                                            </span>
                                                                        </Fragment>
                                                                    )}
                                                                    {property.baths != 0 && property.baths && (
                                                                        <Fragment>
                                                                            <img loading='lazy' className={`${style.propCard_topContent_icon}`} src={shower} alt="" />
                                                                            <span className={`${style.propCard_topContent_iconText}`}>{property.baths}</span>
                                                                        </Fragment>
                                                                    )}
                                                                    {property.buildup != 0 && property.buildup && (
                                                                        <Fragment>
                                                                            <img loading='lazy' className={`${style.propCard_topContent_icon}`} src={house} alt="" />
                                                                            <span className={`${style.propCard_topContent_iconText}`}>{property.buildup} ft.²</span>
                                                                        </Fragment>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <img loading='lazy' className={`${style.propCard_topContent_icon}`} src={calendar} alt="" />
                                                                    <span className={`${style.propCard_topContent_createdDate}`}>{moment(property.created).format("DD-MM-YYYY")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        {property.description && (
                                            <div className={`${style.propCard_middle}`}>
                                                <p className={`${style.propCard_description}`}>{property.description}</p>
                                            </div>
                                        )}
                                        <div className={`${style.propCard_bottom}`}>
                                            <div className={`${style.propCard_bottom_content}`}>
                                                <Avatar
                                                    className={`${style.propCard_avatar}`}
                                                    src={
                                                        property.agent.image
                                                            ? property.agent.image
                                                            : SetupData.profile.imgURL
                                                    }
                                                />
                                                <a href={goToAgentPage(property.agent)} className={`${style.propCard__agentInfo} ${style.redirectLink}`} rel="noopener noreferrer" target="_blank" onClick={() => localStorage.setItem('from', "map")}>
                                                    <span className={`${style.propCard__agentCompany}`}>{property.agent.companyName}</span>
                                                    <span className={`${style.propCard__agentName}`}>{property.agent.nickname || property.agent.name}</span>
                                                </a>
                                            </div>
                                            <img loading='lazy' className={`${style.propCard_agentMobile_btn}`} onClick={() => handlePhone(property.agent.mobile)} src={phone} alt="" />
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </TabPane>
                    </TabContent>
                </div>
            </Modal>
            }
            <Footer2 />
        </>
    );
};

export default AgentDetailPage;
