import React from 'react';
import { useTranslation } from 'react-i18next';

const PageError = () =>{
    const { t } = useTranslation();
    return (
        <>
        <pre className="pre-error-container-height"></pre>
        <div className="pre-error-container">
            <p className="lead">{`${t('page-not-found')}.`}</p>
            <a href="/">{t('back-to-homepage')}</a>
        </div>
        </>
    );  
};

export default PageError;