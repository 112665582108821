import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useHistory } from "react-router-dom";

// Redux imports
import { useDispatch, useSelector } from "react-redux";
import { close } from "../redux/actions/emblemActions";

// Styles imports
import "../styles/pages/agentSearchPage.scss";
import style from "../styles/pages/agentSearchPage.module.scss";

// React-icons imports
import { MdClose } from "react-icons/md";

// Reactstrap imports
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";

// Assets imports
import nextsixAppEmblem from "../assets/home/nextsix-app-emblem.png";
import whiteSearch from "../assets/agentSearch/whiteSearch.svg";
import dropdownGrey from "../assets/img/dropdowngrey.svg";
import mapMobileview from "../assets/img/map-mobileview.svg";
import listingMobileview from "../assets/img/listing-mobileview.svg";
import infoIconFlag from "../assets/agentSearch/whiteInfoIcon.svg";
import infoCenterFlag from "../assets/agentSearch/orangeInfoCenter.svg";
import phone from "../assets/agentSearch/greenPhone.svg";
import bed from "../assets/agentSearch/whiteBed.svg";
import shower from "../assets/agentSearch/whiteShower.svg";
import landWhiteIcon from "../assets/agentSearch/icon_land_white.svg";
import house from "../assets/agentSearch/whiteHouse.svg";
import calendar from "../assets/agentSearch/whiteCalendar.svg";
import star from "../assets/agentSearch/whiteStar.svg";
import pointer from "../assets/agentSearch/greyPointer.svg";
import building from "../assets/agentSearch/greyBuilding.svg";

// Components imports
import Header2 from "../components/Layout/Header2";
import Footer2 from "../components/Layout/Footer2";
import AgentSuggestion from "../components/AgentSuggestion";
import Avatar from "../components/Avatar";
import AgentCard from "../components/Layout/AgentCard";

// Package imports
import Placeholder from "react-loading-skeleton";
import Select, { components } from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";
import GoogleMapReact from "google-map-react";
import moment from "moment";
import qs from "qs";
import clsx from "clsx";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import lib from "../businesses/lib";

// Business imports
import agentComponent from "../businesses/agent";
import seoComponent from "../businesses/seoComponent";
import SetupData from "../data/SetupData";
import sharedComponent from "../businesses/sharedComponent";
import string from "../businesses/string";
import Table from "../components/Table";
import CustomBreadcrumb from "../components/Breadcrumb";

export default function AgentSearchPage2() {
  const history = useHistory();
  const { state, area, type } = useParams();
  const urlLocation = useLocation();

  // Redux related

  const dispatch = useDispatch();
  const emblem = useSelector((state) => state.emblem);

  const [agentsList, setAgentsList] = useState([]);
  const [infoCenterList, setInfoCenterList] = useState([]);
  const [stateAreaOptions, setStateAreaOptions] = useState();
  const allStateAreas = useRef();
  const responseObj = useRef();
  const [loaded, setLoaded] = useState(null);

  // Mobile related

  const [isMobile, setIsMobile] = useState(false);

  // Map related

  const [showMap, setShowMap] = useState(false);
  const [defaultZoom, setDefaultZoom] = useState(8);
  const [defaultLocation, setDefaultLocation] = useState({
    //The Roof Realty Sdn Bhd
    // lat: 3.085849,
    // lng: 101.692614,
    //Malaysia
    lat: 4.2105,
    lng: 101.9758,
  });

  // Search related

  const [searchQuery, setSearchQuery] = useState();

  // Translations related

  const { t } = useTranslation();

  // Breadcrumns
  const [breadcrumb, setBreadcrumb] = useState({
    home: {
      title: "Home",
      url: "/",
    },
    for: {
      title: `Agents`,
      url: "/agent/all-states/all-areas/all-types",
    },
    state: { title: undefined, url: "" },
    area: { title: undefined, url: "" },
    type: { title: undefined, url: "" },
  });

  // React-select related

  const customStyles = {
    container: (base) => ({
      ...base,
      // padding: 0,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 2,
      borderRadius: "7px",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    control: (base) => ({
      ...base,
      backgroundColor: "transparent",
      border: "1px solid #a7a8ab",
      borderRadius: "7px",
      boxShadow: "none",
      cursor: "pointer",
      minHeight: "31px",
      padding: "0px 15px",
      "&:hover": {
        backgroundColor: "#f9e0c0",
      },
    }),
    option: (base) => ({
      ...base,
      cursor: "pointer",
    }),
    valueContainer: (base) => ({
      ...base,
      fontWeight: 400,
      fontSize: "12px",
      padding: "0px",
    }),
    singleValue: (base) => ({
      ...base,
      margin: 0,
      color: "#58595b",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "0px",
    }),
    placeholder: (base) => ({
      ...base,
      margin: 0,
      fontWeight: 400,
      fontSize: "16px",
      color: "#58595b",
      fontSize: "12px",
    }),
    menuPortal: (base) => ({
      ...base,
      width: "100%",
      left: 0,
      right: 0,
      top: 0,
    }),
    menuList: (base) => ({
      ...base,
      borderRadius: "inherit",
    }),
  };

  const agentTypeOptions = [{ label: "All types", value: "" }];
  SetupData.agent.type.map((type) =>
    agentTypeOptions.push({
      label: type.label,
      value: type.value,
    })
  );

  function DropdownIndicator(dropdownProps) {
    return (
      <components.DropdownIndicator {...dropdownProps}>
        <img loading='lazy' src={dropdownGrey} alt="Dropdown Arrow" />
      </components.DropdownIndicator>
    );
  }

  function formatOptions(type, dependency) {
    let options = [];
    if (type === "state") {
      options.push({
        label: "All States",
        value: "",
      });
      allStateAreas.current.map((obj) => {
        options.push({
          label: obj.state,
          value: obj.state,
        });
      });
    }
    if (type === "area") {
      const selectedState = dependency;
      const selectedStateObj = allStateAreas.current.find(
        (obj) => obj.state === selectedState
      );
      if (selectedStateObj) {
        options.push({
          label: "All Areas",
          value: "",
        });
        selectedStateObj.areas.map((obj) => {
          options.push({
            label: obj,
            value: obj,
          });
        });
      }
    }

    return options;
  }

  // Init functions

  async function getAreas() {
    const result = await sharedComponent.getAreas();
    allStateAreas.current = result;
    const formattedState = formatOptions("state");
    setStateAreaOptions((stateAreaOptions) => ({
      ...stateAreaOptions,
      states: formattedState,
    }));
    if (state) {
      const formattedArea = formatOptions(
        "area",
        string.capitalize(seoComponent.seoToState(state))
      );
      setStateAreaOptions((stateAreaOptions) => ({
        ...stateAreaOptions,
        areas: formattedArea,
      }));
    }
  }

  // Normal functions

  function checkDeviceType() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTouchDevice =
      "maxTouchPoints" in navigator && navigator.maxTouchPoints > 0;

    if (
      isTouchDevice &&
      (userAgent.includes("mobile") ||
        userAgent.includes("android") ||
        userAgent.includes("iphone"))
    ) {
      setIsMobile(true);
    }
  }

  async function getAgentsList() {
    const result = await validateUrlLocation();
    if (result.ok) {
      const urlSearch = result.urlSearch;
      setSearchQuery(urlSearch);
      setLoaded(false);
      await fetchInitialAgents(urlSearch);
    } else {
      //NXT-1876
      history.push('/error/404');
    }
  }

  async function fetchInitialAgents(urlSearch) {
    const response = await agentComponent.webSearch(urlSearch, {page: urlSearch.page});
    responseObj.current = response;
    setAgentsList(response.data);
    setInfoCenterList(response.infoCenter);
    setLoaded(true);
  }

  const invalid = (err) => {
    return {
      ok: false,
      err: err
    }
  };

  async function validateUrlLocation() {
    let urlSearch = {};
    if (state) {
      let selectedState = seoComponent.seoToState(state);
      if (selectedState === seoComponent.seoConstant.allStates) selectedState = "";
      urlSearch.state = selectedState;
    }

    if (area) {
      let selectedArea = seoComponent.seoToState(area);
      if (selectedArea === seoComponent.seoConstant.allAreas) selectedArea = "";
      urlSearch.area = selectedArea;
    }

    if (type) {
      let selectedType = seoComponent.seoToState(type);
      if (selectedType === seoComponent.seoConstant.allAgentTypes) selectedType = '';
      else { //NXT-1876
        if (selectedType) {
          let typeFound = SetupData.agent.type.find(obj => obj.value.toLowerCase() === selectedType);
          if (!typeFound) return invalid();
        } else return invalid();
      }
      urlSearch.type = selectedType;
    }

    //NXT-1876
    //state and area checking pending first due to SEO url not in latest format
    //check state and area valid or not
    // if (urlSearch.state) {
    //   console.log(urlSearch.area)
    //   const beautifiedState = seoComponent.beautifyState(urlSearch.state);
    //   console.log(beautifiedState)
    //   const stateArea = { state: beautifiedState };
    //   if (urlSearch.area) stateArea.area = seoComponent.beautifyArea(area);
    //   console.log(stateArea)
    //   const validStateArea = await sharedComponent.validStateArea(stateArea);
    //   if (!validStateArea) return invalid();
    // }

    if (urlLocation.search) {
      const queryParams = qs.parse(urlLocation.search.slice(1));

      if (queryParams.name)
        urlSearch.name = seoComponent.decodeQueryParam(queryParams.name);
      if (queryParams.page)
        urlSearch.page = seoComponent.decodeQueryParam(queryParams.page);
    }
    return {
      ok: true,
      urlSearch: urlSearch,
    };
  }

  function handleSelectChange(name, value) {
    if (name === "state") {
      const selectedStateObj = allStateAreas.current.find(
        (obj) => obj.state === value
      );
      if (selectedStateObj) {
        const formattedArea = formatOptions("area", value);
        setStateAreaOptions((stateAreaOptions) => ({
          ...stateAreaOptions,
          areas: formattedArea,
        }));
      }
      return setSearchQuery({
        ...searchQuery,
        [name]: value,
        area: "",
      });
    }

    return setSearchQuery({
      ...searchQuery,
      [name]: value,
    });
  }

  function handleSearchFor(value) {
    if (value === "Property") window.location = "/properties-for-sale";
  }

  function handleSearchUrl() {
    const {page, ...others} = searchQuery
    // exclude page when its new search
    let newUrl = seoComponent.createSearchAgentUrl(others);
    window.location = newUrl;
  }

  // Hooks

  useEffect(() => {
    checkDeviceType();
    getAreas();
  }, []);

  useEffect(() => {
    getAgentsList();
  }, [urlLocation]);

  // Components

  function MobileAppBanner() {
    return (
      <div className="emblem-wrapper">
        <div className="emblem-left-content">
          <Button
            className="emblem-close-btn"
            onClick={() => dispatch(close("close"))}
          >
            <MdClose />
          </Button>
          <img loading='lazy' className="emblem-logo" src={nextsixAppEmblem} alt="" />
          <div className="emblem-left-textbox">
            <span className="emblem-left-text-top">NextSix App</span>
            <span className="emblem-left-text-btm">
              Find properties and agents faster
            </span>
          </div>
        </div>
        <div className="emblem-right-content">
          <a
            className="emblem-download-btn"
            href="https://apps.nextsix.com/users/reg"
          >
            Use Now
          </a>
        </div>
      </div>
    );
  }

  function MapSkeleton({ className, containerClassName }) {
    return (
      <Placeholder
        className={className}
        containerClassName={containerClassName}
      />
    );
  }

  function ListSkeleton({ className }) {
    return (
      <div className={`row ${className}`}>
        <div className="col">
          <div className="row mb-2">
            <div
              className={clsx({
                "col-6": true,
                "col-md-4 col-xl-2": showMap,
                "col-md-2 col-xl-1": !showMap,
              })}
            >
              <Placeholder />
            </div>
          </div>
          <div className="row">{cardSkeleton(18)}</div>
        </div>
      </div>
    );
  }

  function cardSkeleton(total) {
    const cards = [];
    for (let i = 0; i < total; i++) {
      cards.push(
        <div
          className={clsx({
            "col-6": true,
            "col-xl-4": showMap,
            "col-md-4 col-xl-2": !showMap,
          })}
          key={i}
        >
          <Card className="agentsp-list-placeholder-card">
            <Placeholder
              className="card-image"
              containerClassName="agentsp-map-placeholder-wrapper"
            />
            <CardBody>
              <CardTitle className="w-75">
                <Placeholder />
              </CardTitle>
              <CardText>
                <Placeholder />
              </CardText>
              <CardText className="w-25">
                <Placeholder />
              </CardText>
            </CardBody>
          </Card>
        </div>
      );
    }
    return cards;
  }

  function Skeleton() {
    return (
      <>
        {showMap && (
          <div className={clsx({ "col-12": true, "col-md-6": !isMobile })}>
            <MapSkeleton
              className="agentsp-map-placeholder"
              containerClassName="agentsp-map-placeholder-wrapper"
            />
          </div>
        )}

        {!isMobile ? (
          <div
            className={clsx({
              "col-12 agentsp-custom-height": true,
              "col-md-6": showMap,
            })}
            style={{ "--agentsp-custom-height": "800px" }}
          >
            <ListSkeleton
              className={clsx({
                "agentsp-list-placeholder-wrapper": true,
                "agentsp-list-placeholder-wrapper-offset": showMap,
              })}
            />
          </div>
        ) : showMap ? null : (
          <div
            className={clsx({
              "col-12 agentsp-custom-height": true,
              "col-md-6": showMap,
            })}
            style={{ "--agentsp-custom-height": "800px" }}
          >
            <ListSkeleton
              className={clsx({
                "agentsp-list-placeholder-wrapper": true,
                "agentsp-list-placeholder-wrapper-offset": showMap,
              })}
            />
          </div>
        )}
      </>
    );
  }

  function EmptyState() {
    return (
      <div
        className="col-12 agentsp-custom-height"
        style={{ color: "#CF2727", "--agentsp-custom-height": "100vh" }}
      >
        <div className="row">
          {showMap && (
            <div className={clsx({ "col-12": true, "col-md-6": !isMobile })}>
              <div className="agentsp-map-placeholder">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_API_KEY,
                  }}
                  center={defaultLocation}
                  zoom={defaultZoom}
                  options={{ gestureHandling: "greedy" }}
                  yesIWantToUseGoogleMapApiInternals
                // onGoogleApiLoaded={({ map, maps }) => ModelsMap(map, maps)}
                ></GoogleMapReact>
              </div>
            </div>
          )}

          {!isMobile ? (
            <div className="col">{t("results-not-found")}</div>
          ) : showMap ? null : (
            <div className="col">{t("results-not-found")}</div>
          )}
        </div>
      </div>
    );
  }

  const UrlNav = () => {
    const finalUrlSearch = searchQuery;

    const breadcrumbData = breadcrumb;

    let seoState = "";
    let seoArea = "";
    let seoType = "";
    
    if(finalUrlSearch?.type) {
      seoType = seoComponent.stateToSeo(finalUrlSearch.type)
      if(seoType === type) {
        breadcrumbData.type.title = lib.toTitleCase(finalUrlSearch.type);
        breadcrumbData.type.url = `/agent/all-states/all-areas/${seoType}`
      }
    }
    if (finalUrlSearch?.state) {
      seoState = seoComponent.stateToSeo(finalUrlSearch.state);
      seoArea = seoComponent.stateToSeo(finalUrlSearch.area);
      seoType = seoComponent.stateToSeo(finalUrlSearch.type);

      if(seoState === state) {
        breadcrumbData.state.title = lib.toTitleCase(finalUrlSearch.state);
        breadcrumbData.state.url = `/agent/${seoState}/all-areas/all-types`
      }

      if(seoArea === area && seoArea !== "all-areas") {
        breadcrumbData.area.title = lib.toTitleCase(finalUrlSearch.area);
        breadcrumbData.area.url = `/agent/${seoState}/${seoArea}/all-types`
      }

      if(seoType === type && seoType !== "all-types") {
        breadcrumbData.type.title = lib.toTitleCase(finalUrlSearch.type);
        breadcrumbData.type.url = `/agent/${seoState}/${seoArea === undefined ? 'all-areas' : seoArea}/${seoType}`
      }

      setBreadcrumb(breadcrumb)

      return (
         <CustomBreadcrumb location={urlLocation} items={breadcrumb} />
      );
    }

    return (
      <CustomBreadcrumb location={urlLocation} items={breadcrumbData} />
    )
  };

  return (
    <>
      {isMobile && emblem === "open" && <MobileAppBanner />}
      <Header2 />
      <Container fluid className="property-list-empty"></Container>
      <div
        // className="agentsp-custom-m-t agentsp-custom-m-x agentsp-wrapper"
        className={clsx({
          "agentsp-custom-m-t agentsp-custom-m-x": true,
          "agentsp-custom-p-x agentsp-wrapper": !showMap,
        })}
        style={{
          "--agentsp-custom-m-t": "18px",
          "--agentsp-custom-m-b": "30px",
          "--agentsp-custom-p-x": "15px",
        }}
      >
        <UrlNav />
        {loaded !== null &&
          <>
            <div
              className={clsx({
                row: true,
                "agentsp-map-refiner-sticky": showMap,
              })}
            >
              <div
                className={clsx({
                  "col-12 pt-3": true,
                  "col-xl-6": showMap,
                })}
              >
                <div className="row">
                  {/* Selector */}
                  <div className="col-12 col-md-auto mb-3">
                    <div className="d-flex flex-row agentsp-selector">
                      <button
                        className="agentsp-button selector"
                        onClick={() => handleSearchFor("Property")}
                      >
                        {t("property")}
                      </button>
                      <button className="agentsp-button selector active">
                        {t("agent")}
                      </button>
                    </div>
                  </div>

                  {/* Search Bar */}
                  <div
                    className={clsx({
                      "col-12 col-md mb-3": true,
                      "col-lg-6": !showMap,
                    })}
                  >
                    <div className="d-flex flex-row">
                      <div className="agentsp-text-input-wrapper">
                        {searchQuery && (
                          <AgentSuggestion
                            defaultText={searchQuery.name}
                            callback={({ value }) =>
                              setSearchQuery({ ...searchQuery, name: value })
                            }
                            searchEnter={handleSearchUrl}
                          />
                        )}
                      </div>
                      <button
                        type="button"
                        className="agentsp-button search"
                        onClick={handleSearchUrl}
                      >
                        <img loading='lazy'
                          src={whiteSearch}
                          alt=""
                          className="agentsp-custom-m-b"
                          style={{ "--agentsp-custom-m-b": "3px" }}
                        />
                      </button>
                    </div>
                  </div>

                  {/* Toggle Map */}
                  {!isMobile && (
                    <div className="col-12 col-md-auto ml-auto pl-0 mb-3 d-flex align-items-center justify-content-end">
                      <div className="row align-items-center justify-content-end">
                        <div className="col-auto pr-2 agentsp-text-darker-grey agentsp-show-map text">
                          Show Map
                        </div>
                        <div className="col-auto pl-0">
                          <label className="switch m-0">
                            <input
                              type="checkbox"
                              onChange={() => setShowMap(!showMap)}
                              checked={showMap}
                            />
                            <span className="agentsp-slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row align-items-center">
                  {/* Search Refiner */}
                  <div className="col-12">
                    <div className="row align-items-center">
                      {/* State */}
                      <div
                        className={clsx({
                          "col-6 col-sm-4 col-md-3 agentsp-custom-m-b": true,
                          "col-lg-3": showMap,
                          "col-lg-2": !showMap,
                        })}
                        style={{ "--agentsp-custom-m-b": "8px" }}
                      >
                        <Select
                          classNamePrefix="agentsp-select"
                          // menuIsOpen={true}
                          isSearchable={false}
                          options={stateAreaOptions?.states}
                          components={{ DropdownIndicator }}
                          styles={customStyles}
                          placeholder={t("state")}
                          value={
                            searchQuery?.state && {
                              label: string.capitalize(searchQuery.state),
                              value: string.capitalize(searchQuery.state),
                            }
                          }
                          onChange={(e) => handleSelectChange("state", e.value)}
                        />
                      </div>

                      {/* Area */}
                      <div
                        className={clsx({
                          "col-6 col-sm-4 col-md-3 agentsp-custom-m-b": true,
                          "col-lg-3": showMap,
                          "col-lg-2": !showMap,
                        })}
                        style={{ "--agentsp-custom-m-b": "8px" }}
                      >
                        <Select
                          classNamePrefix="agentsp-select"
                          // menuIsOpen={true}
                          isSearchable={false}
                          options={stateAreaOptions?.areas}
                          components={{ DropdownIndicator }}
                          styles={customStyles}
                          placeholder={t("area")}
                          value={
                            searchQuery?.area && {
                              label: string.capitalize(searchQuery.area),
                              value: string.capitalize(searchQuery.area),
                            }
                          }
                          onChange={(e) => handleSelectChange("area", e.value)}
                        />
                      </div>

                      {/* Agent Type */}
                      <div
                        className={clsx({
                          "col-6 col-sm-4 col-md-3 agentsp-custom-m-b": true,
                          "col-lg-3": showMap,
                          "col-lg-2": !showMap,
                        })}
                        style={{ "--agentsp-custom-m-b": "8px" }}
                      >
                        <Select
                          classNamePrefix="agentsp-select"
                          // menuIsOpen={true}
                          isSearchable={false}
                          options={agentTypeOptions}
                          components={{ DropdownIndicator }}
                          styles={customStyles}
                          placeholder={t("agent-type")}
                          value={
                            searchQuery?.type && {
                              label: string.capitalize(searchQuery.type),
                              value: string.capitalize(searchQuery.type),
                            }
                          }
                          onChange={(e) => handleSelectChange("type", e.value)}
                        />
                      </div>

                      {/* Mobile Toggle */}
                      {isMobile && (
                        <div
                          className={clsx({
                            "col-6 col-sm-4 col-md-3 agentsp-custom-m-b": true,
                            "col-lg-3": showMap,
                            "col-lg-2": !showMap,
                          })}
                          style={{ "--agentsp-custom-m-b": "8px" }}
                        >
                          <div className="row justify-content-end justify-content-md-start">
                            <div className="col-auto">
                              <button
                                type="button"
                                className="agentsp-button mobile-toggle"
                                onClick={() => setShowMap(!showMap)}
                              >
                                <img loading='lazy'
                                  src={showMap ? listingMobileview : mapMobileview}
                                  alt=""
                                  className="mr-1 agentsp-custom-m-b"
                                  style={{
                                    "--agentsp-custom-m-b": showMap ? "2px" : "5px",
                                  }}
                                />
                                {showMap ? "Listing" : "Map"}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row agentsp-custom-m-y"
              style={{ "--agentsp-custom-m-y": "30px" }}
            >
              {loaded === false ? (
                <Skeleton />
              ) : agentsList?.length === 0 ? (
                <EmptyState />
              ) : (
                <Layout
                  agents={agentsList}
                  setAgents={setAgentsList}
                  defaultLocation={defaultLocation}
                  setDefaultLocation={setDefaultLocation}
                  defaultZoom={defaultZoom}
                  setDefaultZoom={setDefaultZoom}
                  infoCenters={infoCenterList}
                  showMap={showMap}
                  isMobile={isMobile}
                  responseObj={responseObj}
                  t={t}
                  searchQuery={searchQuery}
                />
              )}
            </div>
          </>
        }
      </div>
      <Footer2 />
    </>
  );
}

function Layout({
  agents,
  setAgents,
  defaultLocation,
  setDefaultLocation,
  defaultZoom,
  setDefaultZoom,
  infoCenters,
  showMap,
  isMobile,
  responseObj,
  t,
  searchQuery,
}) {
  // Normal functions

  function goToAgentPage(agent) {
    const newUrl = seoComponent.createAgentDetailUrl(agent);
    return newUrl;
  }

  function goToPropertyPage(property) {
    const newUrl = seoComponent.createPropertyDetailUrl(property);
    return newUrl;
  }

  return (
    <>
      {showMap && (
        <div className={clsx({ "col-12": true, "col-md-6": !isMobile })}>
          <Map
            agents={agents}
            defaultLocation={defaultLocation}
            setDefaultLocation={setDefaultLocation}
            defaultZoom={defaultZoom}
            setDefaultZoom={setDefaultZoom}
            infoCenters={infoCenters}
            goToAgentPage={goToAgentPage}
            goToPropertyPage={goToPropertyPage}
          />
        </div>
      )}

      {!isMobile ? (
        <AgentsList
          agents={agents}
          setAgents={setAgents}
          isMobile={isMobile}
          showMap={showMap}
          responseObj={responseObj}
          t={t}
          searchQuery={searchQuery}
          goToAgentPage={goToAgentPage}
        />
      ) : showMap ? null : (
        <AgentsList
          agents={agents}
          setAgents={setAgents}
          isMobile={isMobile}
          showMap={showMap}
          responseObj={responseObj}
          t={t}
          searchQuery={searchQuery}
          goToAgentPage={goToAgentPage}
        />
      )}
    </>
  );
}

function Map({
  agents,
  defaultLocation,
  setDefaultLocation,
  defaultZoom,
  setDefaultZoom,
  infoCenters,
  goToAgentPage,
  goToPropertyPage,
}) {
  // Modal related

  const [showInfoCenterModal, setShowInfoCenterModal] = useState(false);
  const [activeFlagTab, setActiveFlagTab] = useState("Property");

  // Hooks

  useEffect(() => {
    if (
      infoCenters &&
      infoCenters[0] &&
      infoCenters[0].loc &&
      infoCenters[0].loc.coordinates
    ) {
      setDefaultLocation({
        lat: infoCenters[0].loc.coordinates[1],
        lng: infoCenters[0].loc.coordinates[0],
      });
      setDefaultZoom(15);
    } else {
      if (agents) {
        if (agents[0] && agents[0].loc && agents[0].loc.coordinates && agents[0].canShowOnMap && agents[0].hasImage) {
          setDefaultLocation({
            lat: agents[0].loc.coordinates[1],
            lng: agents[0].loc.coordinates[0],
          });
          setDefaultZoom(15);
        } else if (agents[1] && agents[1].loc && agents[1].loc.coordinates && agents[1].canShowOnMap && agents[1].hasImage) {
          setDefaultLocation({
            lat: agents[1].loc.coordinates[1],
            lng: agents[1].loc.coordinates[0],
          });
          setDefaultZoom(15);
        }
      }
    }
  }, []);

  // Normal functions

  function handlePhone(mobile) {
    const phone = parsePhoneNumberFromString(mobile, "MY").nationalNumber;
    window.location = `tel:+60${phone}`;
  }

  // Components

  function AgentWithLocation(props) {
    const agent = props.agent;
    return (
      <a
        href={goToAgentPage(agent)}
        className={`${style.mapBlock_agent_marker}`}
        onClick={() => localStorage.setItem("from", "map")}
      >
        <img loading='lazy'
          style={{ cursor: "pointer" }}
          className={`${style.mapBlock_agentImg}`}
          id={`btn-${props.index}`}
          src={agent.image ? agent.image : SetupData.defaultPfp}
          alt={
            agent &&
            (agent.nickname ? agent.nickname : agent.name) +
            " Property Agent in " +
            agent?.area +
            " " +
            agent.state?.replace(`Wilayah Persekutuan`, ``).trim()
          }
          title={agent.nickname ? agent.nickname : agent.name}
        />
      </a>
    );
  }

  return (
    <div className="agentsp-map-placeholder">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_API_KEY,
        }}
        center={defaultLocation}
        zoom={defaultZoom}
        options={{ gestureHandling: "greedy" }}
        yesIWantToUseGoogleMapApiInternals
      // onGoogleApiLoaded={({ map, maps }) => ModelsMap(map, maps)}
      >
        {agents.slice(0, 30).map((agent, idx) => {
          return (
            agent.loc && agent.loc.coordinates && agent.canShowOnMap && agent.hasImage && (
              <AgentWithLocation
                key={idx}
                lat={agent.loc && agent.loc.coordinates[1]}
                lng={agent.loc && agent.loc.coordinates[0]}
                agent={agent}
                index={idx}
              />
            )
          );
        })}
        {infoCenters &&
          infoCenters.map((infoCenter, idx) => {
            return (
              <div
                key={idx}
                className={style.mapBlock_infoCenter_marker}
                lat={infoCenter.loc && infoCenter.loc.coordinates[1]}
                lng={infoCenter.loc && infoCenter.loc.coordinates[0]}
              >
                <div
                  className={`${style.mapBlock_infoCenterImg}`}
                  onClick={() => setShowInfoCenterModal(!showInfoCenterModal)}
                >
                  <div className={`${style.mapBlock_infoCenter_header}`}>
                    <p
                      className={`${style.mapBlock_infoCenter_area}`}
                      style={{ cursor: "pointer" }}
                    >
                      {infoCenter.area}
                    </p>
                    <img loading='lazy'
                      style={{ cursor: "pointer" }}
                      className={`${style.mapBlock_infoCenter_infoIcon}`}
                      src={infoIconFlag}
                      alt=""
                    />
                  </div>
                  <img loading='lazy'
                    style={{ cursor: "pointer" }}
                    src={infoCenterFlag}
                    alt=""
                  />
                </div>
                <Modal
                  className={`${style.infoCenter_popup} agentSearch_flagModal`}
                  isOpen={showInfoCenterModal}
                  toggle={() => setShowInfoCenterModal(!showInfoCenterModal)}
                >
                  <div className={`${style.mapBlock_infoCenterImg}`}>
                    <div className={`${style.mapBlock_infoCenter_header}`}>
                      <p className={`${style.mapBlock_infoCenter_area}`}>
                        {infoCenter.area}
                      </p>
                      <img loading='lazy'
                        className={`${style.mapBlock_infoCenter_infoIcon}`}
                        src={infoIconFlag}
                        alt=""
                      />
                    </div>
                    <img loading='lazy' src={infoCenterFlag} alt="" />
                  </div>
                  <div className={`${style.infoCenter_popup_content}`}>
                    <div className={`${style.infoCenter_popup_close}`}>
                      <MdClose
                        onClick={() =>
                          setShowInfoCenterModal(!showInfoCenterModal)
                        }
                      />
                    </div>
                    <Nav tabs className={`${style.infoCenter_popup_nav}`}>
                      <NavItem
                        className={`${style.infoCenter_popup_navItem} ${style.infoCenter_popup_navItem_property}`}
                      >
                        <NavLink
                          className={
                            activeFlagTab === "Property"
                              ? `${style.infoCenter_popup_navLink_property}`
                              : `${style.infoCenter_popup_navLink}`
                          }
                          onClick={() => setActiveFlagTab("Property")}
                        >
                          Properties
                        </NavLink>
                      </NavItem>
                      <NavItem
                        className={`${style.infoCenter_popup_navItem} ${style.infoCenter_popup_navItem_agent}`}
                      >
                        <NavLink
                          className={
                            activeFlagTab === "Agent"
                              ? `${style.infoCenter_popup_navLink_agent}`
                              : `${style.infoCenter_popup_navLink}`
                          }
                          onClick={() => {
                            setActiveFlagTab("Agent");
                          }}
                        >
                          Agents
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      activeTab={activeFlagTab}
                      className={`${style.infoCenter_tabContent}`}
                    >
                      <TabPane
                        tabId="Property"
                        className={`${style.infoCenter_tabContent_property} custom-infinite-scrollbar2`}
                      >
                        {infoCenter.properties.map((property, i) => {
                          return (
                            <div
                              className={`${style.infoCenter_propCard}`}
                              key={i}
                            >
                              <div className={`${style.propCard_top}`}>
                                <div className={`${style.propCard_imgWrap}`}>
                                  <img loading='lazy'
                                    className={`${style.propCard_img}`}
                                    src={property.images[0]}
                                    alt={
                                      property &&
                                      (property.for == "Rent" ||
                                        property.for == "Buy") &&
                                      property.type +
                                      " " +
                                      (property.for == "Rent"
                                        ? "For Rent"
                                        : "For Sale") +
                                      " in " +
                                      property.address?.area +
                                      " " +
                                      property.address?.state
                                        .replace(`Wilayah Persekutuan`, ``)
                                        .trim()
                                    }
                                  />
                                </div>
                                <div className={`${style.propCard_topContent}`}>
                                  <a
                                    href={goToPropertyPage(property)}
                                    className={`${style.redirectLink}`}
                                    onClick={() =>
                                      localStorage.setItem("from", "map")
                                    }
                                  >
                                    <div
                                      className={`${style.propCard_topContent_innerWrap}`}
                                    >
                                      <p className={`${style.propCard_name}`}>
                                        {property.name}
                                      </p>
                                      <div>
                                        <span
                                          className={`${style.propCard_for}`}
                                        >
                                          {property.for === "Buy"
                                            ? "Buy"
                                            : property.for}
                                        </span>
                                        {property.rentType === "Room Only" && (
                                          <span
                                            className={`${style.propCard_rentType}`}
                                          >
                                            {property.rentType}
                                          </span>
                                        )}
                                        {property.for.toLowerCase() ===
                                          "rent" ? (
                                          <p
                                            className={`${style.propCard_price}`}
                                          >
                                            {`RM ${parseFloat(
                                              property.rent.monthlyRental
                                            ).toLocaleString()}/month`}
                                          </p>
                                        ) : (
                                          <p
                                            className={`${style.propCard_price}`}
                                          >
                                            RM{" "}
                                            {parseFloat(
                                              property.buy.price
                                            ).toLocaleString()}
                                          </p>
                                        )}
                                        <div
                                          className={`${style.propCard_topContent_iconGroup}`}
                                        >
                                          <div>
                                            {property.beds != "Bedrooms" &&
                                              property.beds && (
                                                <>
                                                  <img loading='lazy'
                                                    className={`${style.propCard_topContent_icon}`}
                                                    src={bed}
                                                    alt=""
                                                  />
                                                  <span
                                                    className={`${style.propCard_topContent_iconText}`}
                                                  >
                                                    {property.beds}
                                                  </span>
                                                </>
                                              )}
                                            {property.baths != 0 &&
                                              property.baths && (
                                                <>
                                                  <img loading='lazy'
                                                    className={`${style.propCard_topContent_icon}`}
                                                    src={shower}
                                                    alt=""
                                                  />
                                                  <span
                                                    className={`${style.propCard_topContent_iconText}`}
                                                  >
                                                    {property.baths}
                                                  </span>
                                                </>
                                              )}

                                            {(() => {
                                              if (
                                                property.title ===
                                                "Agricultural Land" ||
                                                property.title ===
                                                "Commercial Land" ||
                                                property.title ===
                                                "Industrial Land" ||
                                                property.title ===
                                                "Residential Land"
                                              ) {
                                                if (property.landArea) {
                                                  return (
                                                    <>
                                                      <img loading='lazy'
                                                        className={`${style.propCard_topContent_icon}`}
                                                        height="12px"
                                                        src={landWhiteIcon}
                                                        alt=""
                                                      />
                                                      <span
                                                        className={`${style.propCard_topContent_iconText}`}
                                                      >
                                                        {property.landArea} ft.²
                                                      </span>
                                                    </>
                                                  );
                                                }
                                              } else {
                                                if (property.buildup) {
                                                  return (
                                                    <>
                                                      <img loading='lazy'
                                                        className={`${style.propCard_topContent_icon}`}
                                                        src={house}
                                                        alt=""
                                                      />
                                                      <span
                                                        className={`${style.propCard_topContent_iconText}`}
                                                      >
                                                        {property.buildup} ft.²
                                                      </span>
                                                    </>
                                                  );
                                                }
                                              }
                                            })()}
                                          </div>
                                          <div>
                                            <img loading='lazy'
                                              className={`${style.propCard_topContent_icon}`}
                                              src={calendar}
                                              alt=""
                                            />
                                            <span
                                              className={`${style.propCard_topContent_createdDate}`}
                                            >
                                              {moment(property.updated).format(
                                                "DD-MM-YYYY"
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              {property.description && (
                                <div className={`${style.propCard_middle}`}>
                                  <p
                                    className={`${style.propCard_description}`}
                                  >
                                    {property.description}
                                  </p>
                                </div>
                              )}
                              <div className={`${style.propCard_bottom}`}>
                                <div
                                  className={`${style.propCard_bottom_content}`}
                                >
                                  <Avatar
                                    className={`${style.propCard_avatar}`}
                                    src={
                                      property.agent.image
                                        ? property.agent.image
                                        : SetupData.profile.imgURL
                                    }
                                    alt={
                                      property.agent &&
                                      property.agent.area &&
                                      property.agent.state &&
                                      (property.agent.nickname
                                        ? property.agent.nickname
                                        : property.agent.name) +
                                      " Property Agent in " +
                                      property.agent?.area +
                                      " " +
                                      property.agent?.state
                                        .replace(`Wilayah Persekutuan`, ``)
                                        .trim()
                                    }
                                  />
                                  <a
                                    href={goToAgentPage(property.agent)}
                                    className={`${style.propCard__agentInfo} ${style.redirectLink}`}
                                    onClick={() =>
                                      localStorage.setItem("from", "map")
                                    }
                                  >
                                    <span
                                      className={`${style.propCard__agentCompany}`}
                                    >
                                      {property.agent.companyName &&
                                        property.agent.renId &&
                                        property.agent.renId !==
                                        "Private Advertiser"
                                        ? property.agent.companyName
                                        : "Private Advertiser"}{" "}
                                    </span>
                                    <span
                                      className={`${style.propCard__agentName}`}
                                    >
                                      {property.agent.nickname ||
                                        property.agent.name}
                                    </span>
                                  </a>
                                </div>
                                <img loading='lazy'
                                  className={`${style.propCard_agentMobile_btn}`}
                                  onClick={() =>
                                    handlePhone(property.agent.mobile)
                                  }
                                  src={phone}
                                  alt=""
                                />
                              </div>
                            </div>
                          );
                        })}
                      </TabPane>
                      <TabPane
                        tabId="Agent"
                        className={`${style.infoCenter_tabContent_agent} custom-infinite-scrollbar2`}
                      >
                        <>
                          {infoCenter.agents &&
                            infoCenter.agents.areaSpecialists.length > 0 &&
                            infoCenter.agents.areaSpecialists.map(
                              (agent, i) => {
                                return (
                                  <a
                                    className={`${style.redirectLink}`}
                                    href={goToAgentPage(agent)}
                                    key={i}
                                    onClick={() =>
                                      localStorage.setItem("from", "map")
                                    }
                                  >
                                    <div
                                      className={`${style.agentCard2_wrapper}`}
                                    >
                                      <div className={`${style.agentCard2}`}>
                                        <div
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <div
                                            className={`${style.agentCard_firstBlock2}`}
                                          >
                                            <img loading='lazy'
                                              className={`${style.agentCard_agentImg2}`}
                                              src={
                                                agent.image
                                                  ? agent.image
                                                  : SetupData.defaultPfp
                                              }
                                              alt={
                                                agent &&
                                                (agent.nickname
                                                  ? agent.nickname
                                                  : agent.name) +
                                                " Property Agent in " +
                                                agent?.area +
                                                " " +
                                                agent?.state
                                                  .replace(
                                                    `Wilayah Persekutuan`,
                                                    ``
                                                  )
                                                  .trim()
                                              }
                                            />
                                            {agentComponent.anyRating(
                                              agent.rating
                                            ) && (
                                                <div
                                                  className={`${style.agentCard_currentStatus2}`}
                                                >
                                                  <p
                                                    className={`${style.agentCard_rank2}`}
                                                  >
                                                    {agent.rating}
                                                    <img loading='lazy'
                                                      className={`${style.agentCard_rankImg}`}
                                                      alt=""
                                                      src={star}
                                                    />
                                                  </p>
                                                </div>
                                              )}
                                          </div>
                                          {agent.status === "online" ? (
                                            <p
                                              className={`${style.agentCard_status_online2}`}
                                            >
                                              &nbsp;
                                            </p>
                                          ) : (
                                            <p
                                              className={`${style.agentCard_status_offline2}`}
                                            >
                                              &nbsp;
                                            </p>
                                          )}
                                        </div>
                                        <div
                                          className={`${style.agentCard_secondBlock2}`}
                                        >
                                          <div
                                            className={`${style.agentCard_name2_row}`}
                                          >
                                            <p
                                              className={`${style.agentCard_name2}`}
                                            >
                                              {agent.nickname
                                                ? agent.nickname
                                                : agent.name}
                                            </p>
                                            <p
                                              className={`${style.agentCard_activeListing2}`}
                                            >
                                              {agent.activeProperty
                                                ? agent.activeProperty
                                                : "0"}{" "}
                                              listings
                                            </p>
                                          </div>
                                          <div
                                            className={
                                              agent.areaSpecialists &&
                                                agent.areaSpecialists.length > 0
                                                ? `${style.agentCard_asTag2}`
                                                : `${style.displayNone}`
                                            }
                                          >
                                            {agent.areaSpecialists &&
                                              agent.areaSpecialists.length >
                                              0 &&
                                              agent.areaSpecialists.length <
                                              2 && (
                                                <>
                                                  <p
                                                    className={`${style.agentCard_asTag_header2}`}
                                                  >
                                                    Area Specialist
                                                  </p>
                                                  {agent.areaSpecialists &&
                                                    agent.areaSpecialists.map(
                                                      (obj, i) => {
                                                        return (
                                                          <p
                                                            className={`${style.agentCard_asTag_text2}`}
                                                            key={i}
                                                          >
                                                            <img loading='lazy'
                                                              className={`${style.agentCard_asTag_img2}`}
                                                              alt=""
                                                              src={pointer}
                                                            />
                                                            {obj.area}
                                                          </p>
                                                        );
                                                      }
                                                    )}
                                                </>
                                              )}
                                          </div>
                                          <div
                                            className={
                                              agent.titledSpecialists &&
                                                agent.titledSpecialists.length > 0
                                                ? `${style.agentCard_tsTag2}`
                                                : `${style.displayNone}`
                                            }
                                          >
                                            {agent.titledSpecialists &&
                                              agent.titledSpecialists.length >
                                              0 && (
                                                <>
                                                  <p
                                                    className={`${style.agentCard_tsTag_header2}`}
                                                  >
                                                    Title Specialist
                                                  </p>
                                                  <img loading='lazy'
                                                    className={`${style.agentCard_tsTag_img2}`}
                                                    alt=""
                                                    src={building}
                                                  />
                                                  {agent.titledSpecialists &&
                                                    agent.titledSpecialists.map(
                                                      (obj, i) => {
                                                        return (
                                                          <p
                                                            className={`${style.agentCard_tsTag_text2}`}
                                                            key={i}
                                                          >
                                                            {obj.title}
                                                            {i ===
                                                              agent
                                                                .titledSpecialists
                                                                .length -
                                                              1
                                                              ? ""
                                                              : ", "}
                                                          </p>
                                                        );
                                                      }
                                                    )}
                                                </>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className={`${style.agentCard_about2}`}
                                      >
                                        {agent.about && agent.about}
                                      </div>
                                    </div>
                                  </a>
                                );
                              }
                            )}
                          {infoCenter.agents &&
                            infoCenter.agents.titledSpecialists.length > 0 &&
                            infoCenter.agents.titledSpecialists.map(
                              (agent, i) => {
                                return (
                                  <a
                                    className={`${style.redirectLink}`}
                                    href={goToAgentPage(agent)}
                                    key={i}
                                    onClick={() =>
                                      localStorage.setItem("from", "map")
                                    }
                                  >
                                    <div
                                      className={`${style.agentCard2_wrapper}`}
                                    >
                                      <div className={`${style.agentCard2}`}>
                                        <div
                                          style={{
                                            position: "relative",
                                          }}
                                        >
                                          <div
                                            className={`${style.agentCard_firstBlock2}`}
                                          >
                                            <img loading='lazy'
                                              className={`${style.agentCard_agentImg2}`}
                                              src={
                                                agent.image
                                                  ? agent.image
                                                  : SetupData.defaultPfp
                                              }
                                              alt={
                                                agent &&
                                                (agent.nickname
                                                  ? agent.nickname
                                                  : agent.name) +
                                                " Property Agent in " +
                                                agent?.area +
                                                " " +
                                                agent?.state
                                                  .replace(
                                                    `Wilayah Persekutuan`,
                                                    ``
                                                  )
                                                  .trim()
                                              }
                                            />
                                            {agentComponent.anyRating(
                                              agent.rating
                                            ) && (
                                                <div
                                                  className={`${style.agentCard_currentStatus2}`}
                                                >
                                                  <p
                                                    className={`${style.agentCard_rank2}`}
                                                  >
                                                    {agent.rating}
                                                    <img loading='lazy'
                                                      className={`${style.agentCard_rankImg}`}
                                                      alt=""
                                                      src={star}
                                                    />
                                                  </p>
                                                </div>
                                              )}
                                          </div>
                                          {agent.status === "online" ? (
                                            <p
                                              className={`${style.agentCard_status_online2}`}
                                            >
                                              &nbsp;
                                            </p>
                                          ) : (
                                            <p
                                              className={`${style.agentCard_status_offline2}`}
                                            >
                                              &nbsp;
                                            </p>
                                          )}
                                        </div>
                                        <div
                                          className={`${style.agentCard_secondBlock2}`}
                                        >
                                          <div
                                            className={`${style.agentCard_name2_row}`}
                                          >
                                            <p
                                              className={`${style.agentCard_name2}`}
                                            >
                                              {agent.nickname
                                                ? agent.nickname
                                                : agent.name}
                                            </p>
                                            <p
                                              className={`${style.agentCard_activeListing2}`}
                                            >
                                              {agent.activeProperty
                                                ? agent.activeProperty
                                                : "0"}{" "}
                                              listings
                                            </p>
                                          </div>
                                          <div
                                            className={
                                              agent.areaSpecialists &&
                                                agent.areaSpecialists.length > 0
                                                ? `${style.agentCard_asTag2}`
                                                : `${style.displayNone}`
                                            }
                                          >
                                            {agent.areaSpecialists &&
                                              agent.areaSpecialists.length >
                                              0 &&
                                              agent.areaSpecialists.length <
                                              2 && (
                                                <>
                                                  <p
                                                    className={`${style.agentCard_asTag_header2}`}
                                                  >
                                                    Area Specialist
                                                  </p>
                                                  {agent.areaSpecialists &&
                                                    agent.areaSpecialists.map(
                                                      (obj, i) => {
                                                        return (
                                                          <p
                                                            className={`${style.agentCard_asTag_text2}`}
                                                            key={i}
                                                          >
                                                            <img loading='lazy'
                                                              className={`${style.agentCard_asTag_img2}`}
                                                              alt=""
                                                              src={pointer}
                                                            />
                                                            {obj.area}
                                                          </p>
                                                        );
                                                      }
                                                    )}
                                                </>
                                              )}
                                          </div>
                                          <div
                                            className={
                                              agent.titledSpecialists &&
                                                agent.titledSpecialists.length > 0
                                                ? `${style.agentCard_tsTag2}`
                                                : `${style.displayNone}`
                                            }
                                          >
                                            {agent.titledSpecialists &&
                                              agent.titledSpecialists.length >
                                              0 && (
                                                <>
                                                  <p
                                                    className={`${style.agentCard_tsTag_header2}`}
                                                  >
                                                    Title Specialist
                                                  </p>
                                                  <img loading='lazy'
                                                    className={`${style.agentCard_tsTag_img2}`}
                                                    alt=""
                                                    src={building}
                                                  />
                                                  {agent.titledSpecialists &&
                                                    agent.titledSpecialists.map(
                                                      (obj, i) => {
                                                        return (
                                                          <p
                                                            className={`${style.agentCard_tsTag_text2}`}
                                                            key={i}
                                                          >
                                                            {obj.title}
                                                            {i ===
                                                              agent
                                                                .titledSpecialists
                                                                .length -
                                                              1
                                                              ? ""
                                                              : ", "}
                                                          </p>
                                                        );
                                                      }
                                                    )}
                                                </>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className={`${style.agentCard_about2}`}
                                      >
                                        {agent.about && agent.about}
                                      </div>
                                    </div>
                                  </a>
                                );
                              }
                            )}
                          {infoCenter.agents &&
                            infoCenter.agents.normalAgents.length > 0 &&
                            infoCenter.agents.normalAgents.map((agent, i) => {
                              return (
                                <a
                                  className={`${style.redirectLink}`}
                                  href={goToAgentPage(agent)}
                                  key={i}
                                  onClick={() =>
                                    localStorage.setItem("from", "map")
                                  }
                                >
                                  <div
                                    className={`${style.agentCard2_wrapper}`}
                                  >
                                    <div className={`${style.agentCard2}`}>
                                      <div
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <div
                                          className={`${style.agentCard_firstBlock2}`}
                                        >
                                          <img loading='lazy'
                                            className={`${style.agentCard_agentImg2}`}
                                            src={
                                              agent.image
                                                ? agent.image
                                                : SetupData.defaultPfp
                                            }
                                            alt={
                                              agent &&
                                              (agent.nickname
                                                ? agent.nickname
                                                : agent.name) +
                                              " Property Agent in " +
                                              agent?.area +
                                              " " +
                                              agent?.state
                                                .replace(
                                                  `Wilayah Persekutuan`,
                                                  ``
                                                )
                                                .trim()
                                            }
                                          />
                                          {agentComponent.anyRating(
                                            agent.rating
                                          ) && (
                                              <div
                                                className={`${style.agentCard_currentStatus2}`}
                                              >
                                                <p
                                                  className={`${style.agentCard_rank2}`}
                                                >
                                                  {agent.rating}
                                                  <img loading='lazy'
                                                    className={`${style.agentCard_rankImg}`}
                                                    alt=""
                                                    src={star}
                                                  />
                                                </p>
                                              </div>
                                            )}
                                        </div>
                                        {agent.status === "online" ? (
                                          <p
                                            className={`${style.agentCard_status_online2}`}
                                          >
                                            &nbsp;
                                          </p>
                                        ) : (
                                          <p
                                            className={`${style.agentCard_status_offline2}`}
                                          >
                                            &nbsp;
                                          </p>
                                        )}
                                      </div>
                                      <div
                                        className={`${style.agentCard_secondBlock2}`}
                                      >
                                        <div
                                          className={`${style.agentCard_name2_row}`}
                                        >
                                          <p
                                            className={`${style.agentCard_name2}`}
                                          >
                                            {agent.nickname
                                              ? agent.nickname
                                              : agent.name}
                                          </p>
                                          <p
                                            className={`${style.agentCard_activeListing2}`}
                                          >
                                            {agent.activeProperty
                                              ? agent.activeProperty
                                              : "0"}{" "}
                                            listings
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={`${style.agentCard_about2}`}
                                    >
                                      {agent.about && agent.about}
                                    </div>
                                  </div>
                                </a>
                              );
                            })}
                        </>
                      </TabPane>
                    </TabContent>
                  </div>
                </Modal>
              </div>
            );
          })}
      </GoogleMapReact>
    </div>
  );
}

function AgentsList({
  agents,
  setAgents,
  showMap,
  responseObj,
  t,
  searchQuery,
  goToAgentPage,
}) {
  // Infinite scroll related

  const [page, setPage] = useState(1);
  const [pageSummary, setPageSummary] = useState({});
  const [search, setSearch] = useState(searchQuery)

  //  Hooks

  useEffect(() => {
    setPage(parseInt(search.page));
    setPageSummary(responseObj.current.summary)
  }, [page]);

  // Normal functions
  
  const handlePaginationClick = (event, pageNumber) => {
    setSearch(search => ({
        ...search,
        page: pageNumber
    }));

    const newUrl = seoComponent.createSearchAgentUrl({...search, page: pageNumber});
    window.location = newUrl;
  }

  // Component

  const AgentRows = ({ }) => {
    return (
    <tr
      className="row agentsp-custom-p-s"
      style={{ "--agentsp-custom-p-s": "15px" }}
    >
      {agents &&
      agents.map((agent, idx) => {
        return (
          <td
            key={idx}
            className={clsx({
              "col-6 agentsp-custom-p-s agentsp-custom-p-e agentsp-custom-m-b": true,
              "col-xl-4": showMap,
              "col-md-4 col-xl-2": !showMap,
            })}
            style={{
              "--agentsp-custom-p-s": "0px",
              "--agentsp-custom-p-e": "15px",
              "--agentsp-custom-m-b": "15px",
            }}
          >
            <a
              className={`${style.redirectLink} agentsp-list-card`}
              href={goToAgentPage(agent)}
              onClick={() =>
                localStorage.setItem("from", "listing")
              }
            > 
              <AgentCard agent={agent} idx={idx} />
            </a>
          </td>
        );
      })}
      </tr>
    );
};

  return (
    <div
      className={clsx({
        "col-12 agentsp-custom-height": true,
        "col-md-6": showMap,
      })}
      style={{ position: "unset" }}
    >
      <div
        className={clsx({
          "row agentsp-list-placeholder-wrapper custom-infinite-scrollbar2": true,
          "agentsp-list-placeholder-wrapper-offset": showMap,
        })}
        id="scrollableDiv"
      >
        <div className="col" style={{ position: "unset" }}>
          <div
            className={clsx({
              "row mb-2 agentsp-list-summary sticky": true,
              "pt-3": showMap,
            })}
            style={{
              "--agentsp-custom-sticky-top": showMap ? "0" : "-1px",
            }}
          >
            <div className="col" style={{ position: "unset" }}>
              <p className="m-0">
                {responseObj.current.summary.records + " "}
                <span className="found">
                  result
                  {responseObj.current.summary.records > 1 ? "s " : " "}
                  found
                </span>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col" style={{ position: "unset" }}>
              {agents.length > 0 && (
                <Table
                  page={page || 1}
                  paginationCount={pageSummary.pages}
                  handlePagination={handlePaginationClick}
                  styleClassName={{table:`${style.agentCard_list}`}}
                  style={{table: {display: "block"}, tableBody: {display: "block"}}}
                >
                  <AgentRows />
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
