import React, { useState, useEffect } from 'react';
import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";
import propertyComponent from '../businesses/property';
import seoComponent from '../businesses/seoComponent';
import lib from '../businesses/lib';
import building from "../assets/agentSearch/greyBuilding.svg";
import locPointer from "../assets/agentSearch/greyPointer.svg";
import '../styles/components/propertySuggestion.scss';
import Highlighter from 'react-highlight-words';
import { SiProbot } from 'react-icons/si';
import { BsSearch } from "react-icons/bs";
import global from '../businesses/global';

const PropertySuggestion = (props) => {
    const groupConstant = seoComponent.propertyGroupConstant;

    const [suggest, setSuggest] = useState([]);
    const [busy, setBusy] = useState(false);
    const [query, setQuery] = useState(props.defaultText || '');
    //const [select, setSelect] = useState(false);
    const [suggestResponse, setSuggestResponse] = useState({});

    const [text, setText] = useState('')
    const [isDeleting, setIsDeleting] = useState(false);
    const [speed, setSpeed] = useState(150);
    const [loop, setLoop] = useState(0);

    const [dataText, setDataText] = useState([
        "condo in cheras",
        "penang apartment",
        "kl",
        "kuchai shoplot",
        "jb land",
        "landed in pj",
        "old klang road terrace",
    ])

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            let temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array
    }

    useEffect(() => {
        setDataText(shuffleArray(dataText));
    }, [])

    const i = loop % dataText.length;
    const fullText = dataText[i]

    const handleTyping = () => {
        setText(
            isDeleting
                ? fullText.substring(0, text.length - 1)
                : fullText.substring(0, text.length + 1)
        )

        setSpeed(isDeleting ? 100 : 150)

        if (!isDeleting && text === fullText) {
            setTimeout(() => setIsDeleting(true), 1000)
        } else if (isDeleting && text === '') {
            setIsDeleting(false)
            setLoop(loop + 1)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            handleTyping()
        }, speed)
        return () => clearTimeout(timer)
    })

    useEffect(() => {
        //setSelect(false);
        getSuggestion();
    }, [query]);

    useEffect(() => {
        if (suggestResponse?.keyword === query) {
            setSuggest(suggestResponse.data);
        }

        const rw_2_listbox = document.getElementById('rw_2_listbox')
        if (rw_2_listbox) {
            setTimeout(() => rw_2_listbox.scrollTop = 0, 10)
        }

    }, [suggestResponse]);

    const getSuggestion = async () => {
        setBusy(true);
        setSuggest([]);
        let result;
        if (query && query.length >= 3) {
            result = await propertyComponent.webRevampQuickSearch(query, props.target);
            setSuggestResponse(result);
        }
        setBusy(false);
    };

    const handleChange = (val) => {
        // lib.log('on change')
        // lib.log(val)
        if (typeof val === 'string') {
            setQuery(val);
            if (props.callback) props.callback({
                id: props.callbackId,
                value: {
                    value: val,
                    option: { quickSearchGroup: groupConstant.groupNone, quickSearchValue: val }
                }
            });
        }

    };

    const treatOnSelectVal = val => {
        if (global.CROSS_STATE_AREAS.includes(val?.quickSearchArea) && val?.quickSearchState === global.SELANGOR_AND_KUALA_LUMPUR)
            val.quickSearchState = 'Wilayah Persekutuan Kuala Lumpur';
        return val;
    };

    const handleSelect = (val) => {
        lib.log('** on select');
        //lib.log(val)
        val = treatOnSelectVal(val);

        //setSelect(true);
        if (suggest?.length > 0 && props.callback) props.callback({
            id: props.callbackId,
            value: {
                value: getTextField(val),
                option: val
            }
        });

    };

    const getTextField = (item) => {
        let textField = query;
        if (item.quickSearchGroup === groupConstant.groupAiProperty) textField = item.aiSearchDisplay;
        else if (item.quickSearchGroup === groupConstant.groupArea) textField = item.quickSearchArea;
        else if (item.quickSearchGroup === groupConstant.groupState) textField = item.quickSearchState;
        else if ((item.quickSearchGroup === groupConstant.groupProperty || item.quickSearchGroup === groupConstant.groupPropertyMaster)) textField = item.quickSearchProperty;
        else if (item.quickSearchGroup === groupConstant.groupNone) textField = item.quickSearchValue;
        return textField;
    }

    const highlightStyle = {
        color: 'black',
        padding: '0px',
        backgroundColor: 'transparent',
        fontWeight: '410'
    };

    const customListItem = ({ item }) => {
        let title1, title2, title3, icon, optionalArea;
        if (item.quickSearchGroup === groupConstant.groupAiProperty) {
            title1 = item.aiSearchDisplay;
            title2 = "Recommended";
            title3 = "";
        } else if (item.quickSearchGroup === groupConstant.groupArea) {
            icon = locPointer;
            title1 = `${item.quickSearchArea}`;
            title2 = "Area";
            title3 = item.quickSearchState;
        } else if (item.quickSearchGroup === groupConstant.groupState) {
            icon = locPointer;
            title1 = item.quickSearchState;
            title2 = "State";
            title3 = "";
        } else if (item.quickSearchGroup === groupConstant.groupProperty || item.quickSearchGroup === groupConstant.groupPropertyMaster) {
            icon = building;
            optionalArea = item.propertyArea ? `${item.propertyArea}, ` : '';
            title1 = `${item.quickSearchProperty}`;
            title2 = item.propertyType;
            title3 = `${optionalArea}${item.propertyState}`;
        }

        return (
            <>
                <div className="suggestion-wrap">
                    <div className='suggestion-top'>
                        <div className="suggestion-icon">
                            {icon ? <img src={icon} alt="" /> : <SiProbot />}
                        </div>

                        <div className="suggestion-title-container">
                            <Highlighter className="suggestion-title1" searchWords={[query]} textToHighlight={title1} highlightStyle={highlightStyle} autoEscape={true} />
                            <span className="suggestion-title2">{title2}</span>
                        </div>
                    </div>

                    <div className="suggestion-bottom">
                        <span className="suggestion-title3">{title3}</span>
                    </div>
                </div>
            </>
        )
    };

    const customTextField = (item) => {
        return getTextField(item);
    };

    const customPlaceholder = () => {
        let placeholder = text;
        if (props.target === "auction-properties-for-sale") placeholder = "Type to Search Property or State";
        else if (props.target === "projects-for-sale") placeholder = "Type to search Project, Area or State";
        //else placeholder = "Type to Search Property, Area or State";
        return placeholder;
    };

    const handleSearchEnter = (e) => {
        //if (e.key.toLowerCase() === "enter" && !select){
        if (e.key.toLowerCase() === "enter" && props.searchEnter) {
            lib.log('Enter is pressed')
            props.searchEnter();
        }
    };

    return (
        <>
            <Combobox
                className="quicksearch"
                busy={busy}
                hideCaret={!busy}
                hideEmptyPopup
                data={suggest}
                placeholder={customPlaceholder()}
                textField={(item) => customTextField(item)}
                filter={false}
                onChange={handleChange}
                onSelect={handleSelect}
                groupBy="item"
                renderListGroup={() => (
                    <div className='click-search'>
                        <div>Click <BsSearch /> to search result related to</div>
                        <span className='click-search-query'>"{query}"</span>
                    </div>
                )}
                renderListItem={(item) => customListItem(item)}
                focusFirstItem={true}
                onKeyUp={handleSearchEnter}
            // open={true}
            //autoSelectMatches={true}
            //autoFocus={true}
            //defaultFocusedItem={null}
            />
        </>
    )
};

export default PropertySuggestion;