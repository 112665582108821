const STATIC = {
  content: {
    Johor: `
      <h2 class="stats-h2">The Historical Background and Development of Johor</h2>
      <p>Johor, Malaysia’s southernmost state, boasts a rich cultural heritage deeply rooted in tradition. Beyond the vibrant dances of Zapin and Kuda Kepang, the state is renowned for its exquisite traditional crafts such as batik, songket and silverwork as well as a rich culinary scene of multicultural flavours. Its history is deeply intertwined with the legacy of Melaka, as it was founded by the royal family who sought refuge after the kingdom fell to Portuguese influence.</p>
      <p>Fast forward to today, and Johor stands as a thriving economic powerhouse. Its strategic location bordering Singapore has been instrumental in driving Johor's development. As a result, the state has become a magnet for both domestic and international residents, contributing significantly to the growth of Johor’s property sale.</p>

      <h2 class="stats-h2">Amenities and Top Shopping Destinations in Johor</h2>
      <p>Johor's strategic location and robust infrastructure have positioned it as a thriving state. Its extensive highway network encompassing Bukit Indah, Iskandar Coastal, Johor Bahru-Kota Tinggi, Kempas, Kota Iskandar, Pasir Gudang, Senai Airport and Skudai Highways ensures seamless transportation options for residents and visitors alike. For those preferring public transit Johor offers comprehensive bus services even connecting to neighbouring Singapore.</p>
      <p>Beyond its connectivity, Johor has cultivated a modern and dynamic lifestyle, offering a wide array of amenities to meet the needs of its residents. A myriad of shopping destinations from upscale malls like Johor Bahru City Square and The Mall Mid Valley Southkey, to convenient neighbourhood shopping centres like AEON and several Mydin Malls cater to diverse preferences. Additionally the presence of IKEA Tebrau adds to the state's appeal for those furnishing their newly invested or rented property in Johor.</p>
      <p>The development of Johor is driven not only by economic growth and property investment but also a strong emphasis on education. Johor boasts a diverse range of educational institutions, catering to various academic pursuits. From public universities like the University of Technology Malaysia (UTM), Multimedia University (MMU) and Universiti Tun Hussein Onn Malaysia (UTHM), to colleges such as Johor Matriculation College, Politeknik Sultan Ibrahim and Crescendo International College, there are ample options for higher education. The state also offers a wide selection of schools, ranging from private international schools to religious schools.</p>
      <p>Johor’s commitment to education and healthcare further solidifies its position as a desirable place to live, work and study. The state boasts world-class medical facilities such as Gleneagles Medini Hospital and Johor Specialist Hospital. Additionally, each district offers convenient access to government hospitals ensuring quality healthcare for all residents.</p>

      <h2 class="stats-h2">Property Investment in Johor</h2>
      <p>Johor's strategic location bordering Singapore makes it an attractive choice for those seeking a balance of work, family life and lifestyle. The state’s unique blend of modernity and culture creates a welcoming environment for residents and visitors alike. This appeal has contributed significantly to Johor’s real estate market and makes it an ideal location for property investment.</p>
      <p>Johor offers a diverse range of neighbourhoods each with its own distinct character. Whether you are a professional seeking a property to match your lifestyle and investment goals or a student looking for suitable shared accommodation, finding the perfect place to <a href="${process.env.REACT_APP_WEBSITE}/properties-for-rent/johor">rent a property</a> is straightforward in Johor.</p>
      <p>Due to its advantageous location, strong infrastructure and first-rate connectivity, Johor has emerged as a dynamic and attractive destination for investors, tenants and families looking for a mix of urban elegance and cultural diversity. This bustling metropolis offers a diverse range of real estate options catering to varied tastes and preferences. The city's real estate market is vibrant and diverse, offering a range of properties from high-rise apartments with breathtaking views of the entire Johor cityscape, culminating at Singapore, to quaint heritage houses nestled amidst lush suburbs.</p>
      <p>Investors are drawn to Johor due to its strong rental yields and comparatively affordable property prices compared to other Asian cities. The city provides a variety of investment opportunities including leasehold and freehold properties. Johor’s ongoing development projects and stable economy make it an appealing choice for both homeowners looking to settle down in the state and property investors seeking long-term growth.</p>

      <h2 class="stats-h2">Attractions in Johor</h2>
      <p>Johor is renowned for its diverse range of attractions that appeal to a wide range of interests. The city's captivating blend of modernity and heritage ensures an unforgettable experience for visitors. Explore the rich cultural tapestry of Johor by visiting Istana Bukit Serene and Tan Hiok Nee Heritage Street.</p>
      <p>For those seeking natural beauty, Gunung Ledang and Desaru Beach offer serene escapes within the city. Adventure seekers can also indulge in thrilling experiences at Legoland Malaysia, tour Danga Bay or have a blast at the Adventure Waterpark Desaru Coast.</p>
      <p><a href="${process.env.REACT_APP_WEBSITE}/properties-for-sale/johor">Buying a property</a> or investing in real estate in Johor offers an exceptional opportunity to be part of the city’s dynamic lifestyle and exciting development. Discover your ideal space among our diverse range of Johor sale property listings and embark on your journey in this thriving metropolis today!</p>
    `,
    Selangor: `
      <h2 class="stats-h2">The Historical Background and Development of Selangor</h2>
      <p>Encircling the federal territories of Kuala Lumpur and Putrajaya, both previously part of its expanse, Selangor is a state on Peninsular Malaysia's west coast comprising nine districts: Sabak Bernam, Kuala Selangor, Hulu Selangor, Petaling, Klang, Kuala Langat, Hulu Langat and Sepang. Though the origin of its name remains unclear, the Selangor River, a vital waterway, played a crucial role in the state's early economic development, transforming it into a thriving trading hub.</p>
      <p>The discovery of tin in the 19th century attracted a diverse influx of immigrants, notably Chinese and Indian labourers, enriching Selangor's multicultural tapestry. As a pivotal player during British colonial times, the state became a founding member of the Federation of Malaya in 1948, laying the groundwork for Malaysia's formation. Today, Selangor's history is celebrated through its cultural and historical sites, drawing visitors eager to explore its heritage as well as homeowners and investors looking to rent or buy a property in Selangor.</p>

      <h2 class="stats-h2">Amenities and Top Shopping Destinations in Selangor</h2>
      <p>As the former home to the Malaysian capital Kuala Lumpur, Selangor offers a wealth of shopping, entertainment and lifestyle amenities catering to both locals and tourists. Its proximity to the city ensures easy access to a wide range of options with no shortage of world-class shopping malls and supermarkets.</p>
      <p>From 1 Utama Shopping Centre, a retail behemoth that once held the title of the largest shopping malls in Malaysia before IOI City Mall in Putrajaya surpassed it in 2022, to the iconic Egyptian-inspired architecture of Sunway Pyramid, Selangor has no shortage of entertainment and retail hubs. Whether you’re simply looking to stock up on household necessities or do some shopping, you’ll be spoilt for choice with malls and supermarkets aplenty such as AEON Shah Alam, AEON Bukit Tinggi, IOI City Mall, Tropicana Gardens Mall, The Mines Shopping Mall and more.</p>
      <p>Selangor also boasts excellent connectivity with the Kuala Lumpur International Airport (KLIA) in Sepang offering global access, while the Subang Airport caters to domestic and private flights. Port Klang, Malaysia's busiest seaport, drives economic activity. As for land travel, the KTM Komuter, Rapid KL Light Rail Transit and MRT lines provide extensive coverage, including key destinations like Kajang, Shah Alam and Petaling Jaya. This robust infrastructure network ensures efficient and hassle-free travel within Selangor and beyond, making it an ideal location for residents, businesses and investors alike.</p>
      <p>Renowned medical facilities, including Sunway Medical Centre, Columbia Asia Hospital, Kajang Hospital, Sungai Buloh Hospital and Tengku Ampuan Rahimah Hospital in Klang, cater to the community's healthcare needs. Selangor also boasts a diverse educational landscape, ranging from primary schools to esteemed universities. Public institutions like Universiti Kebangsaan
      <p>Malaysia, Universiti Putra Malaysia and the International Islamic University of Malaysia are complemented by private universities and colleges such as INTI International College Subang, UCSI University, Multimedia University, Taylor's University and Universiti Tunku Abdul Rahman.</p>
      <p>The abundance of amenities and conveniences contributes to Selangor's high quality of life, making it a prime location if you are considering <a href="${process.env.REACT_APP_WEBSITE}/properties-for-sale/selangor">buying a property in Selangor</a> or investing in real estate.</p>

      <h2 class="stats-h2">Property Investment in Selangor</h2>
      <p>Selangor has emerged as a sought-after destination for property investment, captivating both domestic and international investors. The state's robust economy, strategic location and ongoing development initiatives make it an ideal choice for those considering investing in property.</p>
      <p>Selangor's real estate market is diverse, offering a spectrum of options from luxurious condominiums and gated communities to more affordable housing. Key areas in Selangor such as Petaling Jaya, Subang Jaya and Shah Alam have become property investment hotspots with a plethora of options available for sale or rent, so you’re sure to find plenty of options no matter whether you’re planning to buy or <a href="${process.env.REACT_APP_WEBSITE}/properties-for-rent/selangor">rent a property in Selangor</a>.
      <p>The Selangor state government has implemented investor-friendly policies including tax incentives and streamlined property transaction processes. This, coupled with the presence of multinational corporations and thriving industries, has significantly boosted demand for both residential and commercial properties. On top of that, ongoing infrastructure projects, such as expanded public transportation networks and new township developments, are also enhancing Selangor's appeal as an investment destination. These factors collectively contribute to the state's continuous growth, making property investment in Selangor a promising venture.</p>

      <h2 class="stats-h2">Attractions in Selangor</h2>
      <p>Selangor is home to a variety of attractions that cater to diverse interests, from nature enthusiasts to history buffs. Nature enthusiasts can explore the vast tropical rainforest at the Forest Research Institute Malaysia (FRIM) or embark on thrilling adventures at Sunway Lagoon. History and culture buffs will appreciate the iconic Batu Caves temple and the Royal Selangor Visitor Centre, showcasing the art of pewter. For a unique experience, i-City's dazzling LED light displays and interactive exhibits offer a captivating escape. These are just a few of the many attractions in Selangor that make the state a captivating destination that’s ripe for property investment or even as a forever home.</p>
      <p>Selangor is a dynamic state with a rich history, excellent amenities and numerous attractions. With a thriving economy and continuous development, Selangor is an ideal location for property investment. Whether you're exploring its historical sites, shopping at top destinations, or considering a real estate venture, Selangor offers abundant opportunities and experiences. So don’t hesitate, check out our Selangor property sale and rent listings and invest in the place of your dreams today!</p>
    `,
    Penang: `
          <h2 class="stats-h2">The Historical Background and Development of Penang</h2>
          <p>Penang, one of the most visited states in Malaysia, is situated in the northwest coast of the peninsula. It consists of an island and a part on the mainland of the peninsula, with Georgetown heritage area being a cultural hub for both local and international visitors. Penang’s historical significance stems from its role as a strategic location for trade and commerce, established through a diplomatic exchange between the Kedah Sultanate and the British East India Company.</p>
          <p>Penang, a thriving city-state, has established itself as a dynamic economic hub despite its relatively small size. Its robust manufacturing and services sectors, coupled with a thriving tourism industry, have contributed significantly to its economic success. Beyond its economic prowess, Penang offers a unique blend of cultures, historical sites and natural beauty, making it a captivating destination for visitors and residents alike. The combination of these factors has contributed to Penang's thriving real estate market, attracting both local and foreign investors.</p>

          <h2 class="stats-h2">Amenities and Top Shopping Destinations in Penang</h2>
          <p>As one of the top tourist destinations in Malaysia, Penang offers world-class amenities and convenient transportation options. You can easily reach Penang International Airport in Bayan Lepas for both domestic and international flights. For those who prefer land travel, Penang is well-connected by an extensive network of roads and expressways, including the Butterworth-Kulim Expressway and Gelugor Highway. If you're looking for a more scenic journey, consider taking a ferry to Penang Island. For local commutes, Penang's public transportation system, including Rapid Penang buses and taxis, provides reliable and affordable options.</p>
          <p>Penang is also a shopper's paradise! You can find everything from high-end malls to local markets. Check out Gurney Paragon, Queensbay or Sunway Carnival for a luxurious shopping spree. If you're looking to experience Penang’s retail scene with a side of culture and heritage, head to KOMTAR Tower, Campbell Street Mall or Little India for a more authentic experience.</p>
          <p>Penang is a hub for education, boasting prestigious schools and universities that consistently produce outstanding graduates. From private and public schools to religious institutions, the state offers a diverse range of educational options. For higher education, Penang is home to renowned universities like Universiti Sains Malaysia, INTI International College Penang, UiTM Permatang Pauh, Wawasan Open University and Open University Malaysia.</p>
          <p>In addition to its strong economy and educational institutions, Penang is renowned for its high-quality healthcare services. The state boasts several world-class private hospitals, such as Gleneagles Hospital Penang, Sunway Medical Centre Penang, and KPJ Penang Specialist Hospital. Moreover, every district in Penang has a government hospital, ensuring accessible healthcare for all residents.</p>

          <h2 class="stats-h2">Property Investment in Penang</h2>
          <p>Penang's ideal location, excellent connectivity and transportation options and strong infrastructure make it a great place for investors, renters, and families looking for a mix of city life and culture. The city offers a wide variety of homes to suit different lifestyles, from modern high-rise apartments with beautiful views to charming traditional houses in peaceful neighbourhoods.</p>
          <p>Penang's property prices remain relatively affordable compared to other major cities in Malaysia. Moreover, there is a wide range of properties to choose from, including freehold and leasehold options. With new developments and a strong economy, Penang is a great place for investing and <a href="${process.env.REACT_APP_WEBSITE}/properties-for-sale/penang">buying a property</a> for long-term growth and a vibrant lifestyle.</p>

          <h2 class="stats-h2">Attractions in Penang</h2>
          <p>Unsure on where to go for a day away from the hustle and bustle of the city? Penang offers a delightful blend of natural attractions, cultural experiences, and exciting activities. Explore the lush greenery of Penang Hill and Penang Botanical Gardens, or relax on the sandy shores of Teluk Bahang Beach. Delve into the rich history of Penang by visiting historic sites like Fort Cornwallis and the Penang Peranakan Mansion. For a fun-filled experience, don't miss the exciting attractions at Penang Escape Park, the 3D Trick Art Museum, and the Glass Museum Penang. With so much to see and do, Penang is the perfect destination for an unforgettable getaway.</p>
          <p>Investing or <a href="${process.env.REACT_APP_WEBSITE}/properties-for-rent/penang">renting a property</a> in Penang means being part of the city's exciting energy and future growth. Discover the magic of Penang for yourself! Browse our property listings and find your ideal home or investment in this thriving city today.</p>
      `,
    "Wilayah Persekutuan Kuala Lumpur": `
      <h2 class="stats-h2">The Historical Background and Development of Kuala Lumpur</h2>
      <p>The history of Wilayah Persekutuan Kuala Lumpur dates back to 1857. At that point in time, the now-metropolitan city was but a muddy confluence of the Gombak and Klang rivers, which resulted in it being named “Kuala Lumpur” in the Malay language.</p>
      <p>A century after its founding, Malaysia gained independence in 1957. Kuala Lumpur, initially the capital of the Federation of Malaya, transitioned to become the capital of the newly formed Federation of Malaysia in 1963. Since then, it has blossomed into a bustling metropolis, attracting a diverse population from across the globe. People come to Kuala Lumpur to visit, work, study and make it their home.</p>

      <h2 class="stats-h2">Amenities and Top Shopping Destinations in Kuala Lumpur</h2>
      <p>Kuala Lumpur offers seamless travel with its extensive highway network (NSE, Duke, Kesas) and Kuala Lumpur International Airport. For those who don't drive, a comprehensive public transport system awaits, including rapid rail (KTM Komuter, MRT, KL Monorail) and airport connections (ERL &amp; Skypark Link). Affordable shuttle buses further enhance accessibility, ensuring easy navigation across the city.</p>
      <p>Kuala Lumpur thrives as a modern metropolis, boasting a comprehensive range of amenities that cater to its residents' needs. From world-class shopping malls like Suria KLCC and Pavilion KL to a network of supermarkets including Giant and Village Grocer, the city offers convenient options for everyday essentials and international brands. Traditional wet markets like Pudu Market deliver a variety of fresh produce, while night markets and farmers' markets add a vibrant touch to various neighbourhoods.</p>
      <p>Education also flourishes in Kuala Lumpur, with a plethora of institutions catering to all levels. Several prestigious options like Victoria Institution and Convent Bukit Nenas stand alongside international schools like Garden International and Alice Smith. There are also plenty of higher education opportunities such as the esteemed Universiti of Malaya (UM), the nation's oldest university. The International Islamic University (IIU) and Universiti Tunku Abdul Rahman (UTAR) are just a few of the many other notable institutions available as well.</p>
      <p>Healthcare is also top-notch, with world-class facilities like the National Heart Institute (IJN) and Prince Court Medical Centre leading the pack. The Kuala Lumpur General Hospital offers public healthcare options, while the University Malaya Medical Centre (UMMC) serves as a university hospital.</p>
      <p>Craving a nature escape amidst Kuala Lumpur's bustling energy? Fear not, the city boasts a selection of stunning and tranquil recreational parks. From the well-known KLCC Park to the</p>
      <p>expansive Perdana Botanical Garden and serene Taman Tasik Titiwangsa, these green havens are all conveniently accessible by public transport.</p>

      <h2 class="stats-h2">Property Investment in Kuala Lumpur</h2>
      <p>Kuala Lumpur stands out as a strategic location not only within Malaysia but across Southeast Asia. Its vibrant mix of cultures and booming business scene fosters personal and professional growth. This, coupled with its multicultural charm, makes it an ideal location for property investment.</p>
      <p>Kuala Lumpur is a vibrant city with a thriving property market, making it an attractive location for those considering a move, whether for work, study, or simply a change of scenery. <a href="${process.env.REACT_APP_WEBSITE}/properties-for-rent/kuala-lumpur">Renting a</a> <a href="${process.env.REACT_APP_WEBSITE}/properties-for-rent/kuala-lumpur">property in Kuala Lumpur</a> is easy thanks to the diverse range of neighbourhoods available, each with its own unique character. Students can find suitable shared accommodations to rent while they further their studies in Kuala Lumpur, while professionals can explore options that fit their lifestyle and investment goals.</p>
      <p>Kuala Lumpur’s strategic location, paired with excellent connectivity and robust infrastructure, makes it an attractive destination for investors, renters, or new families to seek for a blend of urban sophistication and cultural richness. Whether you're interested in renting in Kuala Lumpur or purchasing a property, you'll find a wide range of options to choose from. that cater to various lifestyles and preferences. From high-rise condominiums with stunning views of the whole Kuala Lumpur city skyline to charming heritage houses in lush suburbs, the city’s real estate market is both dynamic and varied.</p>
      <p>Investors are particularly drawn to Kuala Lumpur for its relatively affordable property prices compared to other major Asian cities, coupled with strong rental yields. If you’re planning on <a href="${process.env.REACT_APP_WEBSITE}/properties-for-rent/kuala-lumpur">buying a property in Kuala Lumpur</a>, you’re in luck as the city also offers a range of options from freehold to leasehold properties, catering to different investment needs. With ongoing development projects and a stable economic environment, Kuala Lumpur continues to be a prime location for both homebuyers and property investors seeking long-term growth and vibrant city living.</p>

      <h2 class="stats-h2">Attractions in Kuala Lumpur</h2>
      <p>Kuala Lumpur is renowned for its diverse and captivating blend of modern marvels and age-old traditions, ensuring an unforgettable experience for all. Immerse yourself in the city's rich cultural tapestry at vibrant bazaars like Central Market and bustling Chinatown. Escape the urban energy and find serenity at the picturesque Lake Gardens or marvel at the diverse avian wonders housed in the impressive KL Bird Park. Ascend the iconic Petronas Twin Towers Skybridge for breathtaking panoramic views, then indulge in a retail therapy session at the luxurious Suria KLCC and Pavilion Kuala Lumpur. From cultural immersion to awe-inspiring architecture and world-class shopping, Kuala Lumpur offers a kaleidoscope of experiences for everyone.</p>
      <p>Investing in or renting a property in Kuala Lumpur offers a unique opportunity to experience the energetic lifestyle and promising growth of this vibrant city. Ready to experience Kuala Lumpur's charm for yourself? Explore our wide array of property listings and find your perfect place in this thriving city today!</p>
    `,
    Sarawak: `
      <h2 class="stats-h2">The Historical Background and Development of Sarawak</h2>
      <p>Sarawak is the largest state in East Malaysia, located on Borneo Island. The history of Sarawak dates back to the late 1500s, where the royals of Brunei sent a Sultan and warriors to help administer the new area. After being ruled by several parties including the British and Japanese, Sarawak received independence as one of the states in Malaysia. Home to beautiful forest reserves and international &amp; coastal shipping, Sarawak appeals as a hidden gem for those who prefer to visit or live in East Malaysia.</p>
      <p>Sarawak's captivating blend of cultures, stunning landscapes and vibrant tourism scene has drawn residents from across Malaysia and beyond. The state's allure, coupled with its growing economy and development, has made it an attractive destination for those seeking a new home. Many individuals and families have chosen to invest in real estate properties in Sarawak, drawn by the promise of a higher quality of life, natural beauty and investment opportunities.</p>

      <h2 class="stats-h2">Amenities and Top Shopping Destinations in Sarawak</h2>
      <p>Travelling in and out of Sarawak is hassle free with the existence of an extensive network of roads. Sarawak boasts a vast road network of approximately 30,000 kilometres. The Trans-Borneo Highway, spanning 2,083 kilometres, serves as the state's primary artery, connecting major cities and towns and facilitating transportation throughout Sarawak. Sarawak's well-connected transportation network includes two international airports, located in Kuching and Miri, making it easy for residents to travel to other destinations, including West Malaysia.</p>
      <p>As a modern state and metropolis, Sarawak thrives in providing a vast range of amenities to satisfy the demands of its citizens. Satisfy your high-end craves at Vivacity Megamall and the Spring Shopping Mall, or do your grocery shopping spree at Plaza Merdeka. Want to catch the latest movie in theatres? Visit CityOne Megamall for a fun time with your friends and family!</p>
      <p>Sarawak's growth is fueled not only by its preservation of cultural heritage and strong property values but also by its reputation as a hub for world-class education, with several esteemed universities and schools calling the state home. Colleges include Institut Perguruan Guru (IPG) Kampus Rajang &amp; Tun Abdul Razak, Kolej Laila Taib and Politeknik Mukah. For universities, there are UCSI University Sarawak Campus, Universiti Malaysia Sarawak, University of Technology Sarawak and Swinburne University of Technology Sarawak Campus. There are many different types of schools available, including private international schools and religious institutions.</p>
      <p>Sarawak also continues to provide excellent and first-rate healthcare, with fantastic hospitals such as Borneo Medical Centre Kuching and Normah Medical Specialist Centre. Furthermore, every district in Sarawak has its own government hospital, making healthcare easily accessible to everybody.</p>

      <h2 class="stats-h2">Property Investment in Sarawak</h2>
      <p>Sarawak's rapid development and growth have made it an attractive option for real estate investment, resulting in a booming property market. Its prospects attract individuals seeking new opportunities in work, study, or simply a fresh start. Sarawak boasts a diverse range of neighbourhoods, each with its unique charm, making it easy to <a href="${process.env.REACT_APP_WEBSITE}/properties-for-rent/sarawak?sort=default">rent a property in Sarawak</a>. Whether you're a professional looking for a lifestyle-matched home or an investment, or a student in need of suitable shared housing, Sarawak offers something for everyone.</p>
      <p>Sarawak's prime location and growing amenities are drawing investors, renters and families seeking a blend of urban sophistication and rich cultural experiences. Sarawak offers a wide array of property options, both for sale and for rent, to cater to diverse preferences and lifestyles. The state's thriving real estate market boasts everything from modern high-rise apartments with panoramic views of the city to charming heritage homes in peaceful suburban settings.</p>
      <p>Due to its strong rental yields and very low cost of real estate when compared to other prominent Asian metropolitan areas, Sarawak has drawn the attention of investors. Both leasehold and freehold properties are offered by the state to meet a variety of investment demands. Due to its steady economy and continuing development projects, Sarawak is a top choice for long-term growth-oriented real estate investors and individuals looking for a <a href="${process.env.REACT_APP_WEBSITE}/properties-for-sale/sarawak?sort=default">property</a> <a href="${process.env.REACT_APP_WEBSITE}/properties-for-sale/sarawak?sort=default">for sale in Sarawak</a>.</p>

      <h2 class="stats-h2">Attractions in Sarawak</h2>
      <p>Sarawak is well-known for its rich cultural heritage, especially the various ethnicities local to Sarawak. Learn more about the history of Sarawak through historical displays such as Sarawak Museum and Borneo Cultures Museum.</p>
      <p>Away from the bustle of the cities, Sarawak also offers a lush, pristine environment inside the state. For a breath of fresh air, visit Taman Negara Talang Satang, Mount Santubong and Bako National Park.</p>
      <p>Purchasing or renting real estate in Sarawak offers a unique opportunity to benefit from the state's exciting growth and active lifestyle. Check out our comprehensive listings for Sarawak properties.</p>
    `,
    Sabah: `
        <h2 class="stats-h2">The Historical Background and Development of Sabah</strong></h2>
        <p>Sabah, the picturesque 'Land Below the Wind,' is located in the northernmost part of Borneo Island. Sabah’s history dates back to approximately 20,000 to 30,000 years ago, where human settlements were found in the area of the state. During the Japanese invasion in World War II, Sabah was ruled by the North Borneo Chartered Company and even after the war was over, it remained under British rule for several more years. Eventually, Sabah and Sarawak were united with Malaya, forming the independent Federation of Malaysia on September 16, 1963.</p>
        <p>Sabah, a state in Malaysia, is home to a diverse population of over 33 indigenous groups speaking more than 50 languages and 80 dialects. The largest ethnic group in Sabah is the Kadazan-Dusun, comprising 30% of the population.</p>
        <p>As the third-largest island in the world, Sabah boasts a rich cultural heritage and abundant natural resources. The state's economic development is driven by agriculture, tourism and manufacturing. This combination of factors, along with a high quality of life and stunning scenery, has made Sabah an attractive destination for individuals interested in property investment.</p>
        <h2 class="stats-h2">Amenities and Top Shopping Destinations in Sabah</h2>
        <p>Sabah offers a variety of transportation options to make your travels easy and convenient. Kota Kinabalu International Airport serves as a major gateway to the state, connecting Sabah to other parts of Malaysia and the world. For those exploring Sabah by land, the extensive network of roads and highways provides easy access to various destinations.</p>
        <p>Public transportation is readily available for everyone, with buses being the most budget-friendly option. Major bus stations like North Bus Terminal Inanam and KK Central connect you to key cities within Sabah and even across the border to Brunei Darussalam.</p>
        <p>If you're planning an island getaway, Sabah offers boat services from Kota Kinabalu and Semporna, allowing you to explore the beautiful islands and participate in exciting diving trips. With its diverse transportation options, Sabah ensures that getting around is a breeze, allowing you to fully immerse yourself in the beauty and adventure this stunning state has to offer.</p>
        <p>Sabah offers a vibrant shopping scene, catering to both locals and visitors seeking unique souvenirs and keepsakes. For a luxurious shopping experience, head to Imago Shopping Mall or Suria Sabah, where you'll find a wide range of high-end brands and local products. To immerse yourself in the local culture, explore the Kota Kinabalu City Waterfront, where you can discover local markets and indulge in delicious street food.</p>
        <p>Sabah's educational landscape is flourishing, contributing to the state's economic growth and development. The diverse range of schools and universities offers students ample opportunities to acquire the skills and knowledge needed to succeed in today's competitive job market. The state boasts two public universities, Universiti Malaysia Sabah (UMS) and UiTM Sabah, as well as several private colleges. For those seeking a more holistic education, Sabah offers private schools like Maktab Nasional and Seri Insan Borneo School, as well as fully residential institutions like Sekolah Menengah Sains Lahad Datu and Sekolah Menengah Sains Sabah.</p>
        <p>Sabah's healthcare system is also renowned for its quality, with a network of excellent hospitals and clinics. Private hospitals like KPJ Sabah Specialist Hospital and Gleneagles Kota Kinabalu provide world-class medical care, while government hospitals ensure widespread access to healthcare services in every district.</p>
        <h2 class="stats-h2">Property Investment in Sabah</h2>
        <p>Sabah, with its breathtaking natural beauty and tranquil atmosphere, has become a sought-after destination for those seeking a new adventure – and ultimately a property in which to call home. The state's thriving economy and ongoing development projects make it an attractive option for both rental property investors and individuals looking to buy their own homes.</p>
        <p>Sabah offers a diverse range of properties to suit various lifestyles and preferences. From modern high-rise apartments with stunning views to charming heritage homes in serene suburbs, you'll find the perfect property to call your own.</p>
        <p>Whether you're a professional seeking a suitable home or a student in need of shared accommodation, Sabah has a variety of rental properties available that are suitable for both long-term and short-term stays. In fact, the state's growing popularity as a tourist destination has also led to a demand for rental properties for short-term stays.</p>
        <p>Sabah offers a diverse range of real estate options, including both leasehold and freehold properties. The state’s stable economy and ongoing development projects make it a promising market for long-term investors.</p>
        <p>Whether you’re looking to rent a home or invest in a property, Sabah has something to offer. The state’s unique blend of natural beauty, cultural diversity and economic opportunities creates a desirable and fulfilling lifestyle. Investing in properties in Sabah can be a rewarding decision, as the state’s growing popularity and economic growth continue to drive the real estate market.</p>
        <h2 class="stats-h2">Attractions in Sabah</h2>
        <p>Sabah’s natural beauty is renowned, with pristine sandy beaches and crystal-clear seas. Explore the wonders of Sipadan and Tiga Islands, or embark on a thrilling adventure to the summit of Gunung Kinabalu, the highest mountain in Southeast Asia. Immerse yourself in Sabah’s rich cultural heritage at the Borneo Cultural Village, or experience authentic local life at the Tajau Laut Guesthouse. From island hopping to mountain climbing and cultural exploration, Sabah offers a diverse range of activities to suit every adventurer’s interests.</p>
        <p>Discover the exciting opportunities that Sabah offers by <a href="${process.env.REACT_APP_WEBSITE}/properties-for-rent/sabah">renting real estate</a> or delving into our extensive list of <a href="${process.env.REACT_APP_WEBSITE}/properties-for-sale/sabah">properties for sale</a>. Explore our range of Sabah properties to find your perfect match today.</p>
    `,
    "Johor Bahru": `
        <h2 class="stats-h2">The Historical Background and Development of Johor Bahru</h2>
        <p>Johor Bahru, often referred to as "J.B.," has a rich history dating back to its origins as Tanjung Puteri. In 1858, Temenggung Daeng Ibrahim's arrival led to the renaming of the town to Iskandar Puteri. Subsequently, Sultan Abu Bakar bestowed the name "Johor Bahru" to distinguish his dynasty from the old Sultanate of Johor and to symbolise the city's new beginning. This marked a new chapter for the city, replacing the former "Johor Lama" or "Old Johor."</p>
        <p>Johor Bahru, the bustling capital of Johor state, is a major centre for economy and development in Malaysia. The wider region, known as Iskandar Malaysia, encompasses five districts: Johor Bahru, Kluang, Kota Tinggi, Pontian and Kulai. With a population of over a million, Johor Bahru is a thriving city that has experienced significant growth since the launch of the Iskandar Malaysia initiative. Its strategic location near Singapore has also made Johor Bahru a gateway to Malaysia and a rising hub for business, technology and creativity.</p>
        <h2 class="stats-h2">Amenities and Top Shopping Destinations in Johor Bahru</h2>
        <p>Johor Bahru's extensive network of roads and highways makes it easy to get around the city and its surrounding areas. For those who prefer public transportation, Johor Bahru offers a variety of options, including buses and trains. The City Bus Johor Bahru and trains provide convenient connections to Singapore, making it easy to travel between the two countries. Travelling around Johor Bahru is also a breeze with Johor’s very own free bus service, Bas Muafakat Johor. </p>
        <p>If you’re a shopaholic or someone who simply loves browsing the latest retail offerings, fret not because Johor Bahru is a shopper's paradise, with a wide range of malls and shopping centres to choose from. Explore the luxurious offerings at Johor Bahru City Square, KSL City Mall and The Mall, Mid Valley Southkey. These malls feature a diverse mix of high-end brands, affordable fashion, dining options and entertainment for the whole family.</p>
        <p>Beyond its economic growth and thriving property market, Johor Bahru offers a diverse range of educational opportunities. Renowned institutions like the University of Technology Malaysia (UTM), University of Southampton Malaysia, Universiti Tun Hussein Onn Malaysia (UTHM) and Raffles University attract students from both locally and internationally. In addition to these universities, Johor Bahru boasts a variety of schools, including religious schools and private international options. This diverse educational landscape caters to the needs of students seeking different learning experiences and career paths.</p>
        <p>The development of Johor Bahru's education sector has contributed significantly to the city's overall economic growth and development too, as students from all across the country and even from abroad are likely to rent properties within the city. Families looking for a place with a robust educational system are also likely to consider settling down and investing in property in Johor Bahru as a result of the myriad options available.</p>
        <p>Healthcare in Johor is also exceptional, boasting top-notch facilities like KPJ Johor Specialist Hospital, Sultanah Aminah Hospital, Columbia Asia Hospital and Johor Specialist Hospital. Furthermore, every district is equipped with its own government hospital, providing convenient access to healthcare for all residents.</p>
        <h2 class="stats-h2">Property Investment in Johor Bahru</h2>
        <p>Johor Bahru's strategic location, robust infrastructure and excellent connectivity make it a magnet for investors, tenants and families seeking a blend of urban sophistication and cultural richness. The city offers a diverse selection of real estate options, catering to various preferences and lifestyles. Its dynamic property market includes everything from high-rise apartments with stunning views of Johor Bahru stretching to Singapore, to charming heritage homes nestled in green suburban areas.</p>
        <p>Johor Bahru is a vibrant city with a thriving real estate market, attracting individuals considering relocating for work, education or a fresh start. The state offers a diverse range of neighbourhoods, each with its own unique charm, making it easy to find rental properties. Professionals can find options that align with their lifestyle and investment goals, while students can locate suitable shared accommodations.</p>
        <p>Johor Bahru's border location with Singapore makes it an ideal spot for individuals balancing work and family life. Its blend of modernity and rich cultural diversity adds to the city's appeal, making it an excellent choice for property investment in Johor Bahru.</p>
        <h2 class="stats-h2">Attractions in Johor Bahru</h2>
        <p>Johor Bahru offers much more than just economic development and serious business. This vibrant city is packed with exciting attractions for the whole family. From the thrilling rides at Legoland Malaysia to the serene beauty of Danga Bay, there's something for everyone. Immerse yourself in Johor Bahru's rich culture by visiting the Malay Culture Village, Chinese Heritage Museum and Arulmigu Sri Rajakaliamman Glass Temple. For a breath of fresh air, explore the natural beauty of Pulai Waterfall or embark on a firefly tour. With its diverse attractions, Johor Bahru is a destination that caters to all interests. Whether you're seeking adventure, relaxation or cultural enrichment, you'll find it all in this dynamic city.</p>
        <p>Acquiring or <a href="${process.env.REACT_APP_WEBSITE}/properties-for-rent/johor/johor-bahru">renting a property in Johor Bahru</a> presents a great opportunity to tap into the city's exciting growth and energetic way of life. Don't forget to check out our wide range of <a href="${process.env.REACT_APP_WEBSITE}/properties-for-sale/johor/johor-bahru">property listings in Johor Bahru</a> today.</p>
    `,
    Skudai: "",
    Cheras: "",
    Ampang: "",
    Klang: "",
    Puchong: "",
    Kuching: "",
    "Bayan Lepas": "",
    Perak: "",
    Perlis: "",
    Melaka: `
      <h2 class="stats-h2">The Historical Background and Development of Melaka</h2>
      <p>Melaka is a city with such a great historical background that it has soon become a hotspot for real estate investment. Founded in the early 15th century, this UNESCO World Heritage city was also known for its mixture of historical sites and contemporary facilities. In addition, formerly Melaka used to serve as a major trading hub but now has turned into a vibrantly growing city which attracts both tourists and investors because of its unique appeal and development potentials.</p>
      <p>The history of Melaka goes back to about 1400s when Parameswara a prince from Sumatra established the Sultanate at the mouth of the river. With its good position, Melaka soon became an important trading centre attracting merchants from China, India, Arabia among others. Later in 1511, the state fell into capture by Portuguese later to Dutch rule before becoming a British colony always leaving behind something unyielding on their culture or architecture. Currently known as UNESCO site since 2008; it honours its past as well as being a modern metropolis mirroring Malaysia’s rich culture.</p>
      <h2 class="stats-h2">Amenities and Top Shopping Destinations in Melaka</h2>
      <p>Major highways connect the city conveniently to other parts of Malaysia like the North-South Expressway (NSE) and the Coastal Federal Route 5. While the Melaka International Airport (MKZ) mainly receives domestic and regional flights, there has also an increase of bus and taxi services network that makes transport within the city easy.</p>
      <p>Shopping malls such as Mahkota Parade and Dataran Pahlawan feature both foreign labels as well as homegrown products. One cannot go without visiting Jonker Street Night Market at Banda Hilir where one finds an assortment of vibrantly coloured crafts, souvenirs, as well as mouth-watering street edibles.</p>
      <p>Melaka’s is admirable for having reputable schools such as the Convent of Holy Infant Jesus while St Francis Institution stands tall among higher institutions. Universiti Teknikal Malaysia Melaka (UTeM) has become an attraction for students worldwide seeking postgraduate education in Malaysia.</p>
      <p>Healthcare services in Melaka are robust with institutions such as Melaka General Hospital and Pantai Hospital providing a full range of healthcare services to residents as well as visitors.</p>
      <p>Melaka also has a variety of natural attractions, you can take a stroll in the peaceful serenity of Melaka Botanical Garden, unwind at Ayer Keroh Lake Park, or have an unforgettable experience with a scenic river cruise on the beautiful Melaka River for a unique city experience. Additionally, travellers can find several helpful travel guides such as Tripadvisor and Britanica among others that provide additional insights about Melaka which are not so common.</p>
      <h2 class="stats-h2">Property Investment in Melaka</h2><p>Investing funds in Melaka’s real estate can be a wise decision due to its growing economy and favourable location. Famous for its rich history and lively culture, Melaka's property market is attracting more investors as the city progresses forward. There are several new residential, commercial, and mixed-use developments being put out across Melaka that cater to diverse interests since property prices remain low compared to other Malaysian cities.</p>
      <p>There has been an increase in <a href="${process.env.REACT_APP_WEBSITE}/properties-for-rent/melaka">rental property</a> demand particularly within the hospitality range as Melaka attempts to improve its tourist sites while upholding their culture. Consequently, an improved infrastructure and a larger number of business enterprises as well as tourists lead to increased economic activity by the area. Hence a rise in demand for property. Equally important are the several ongoing construction projects, enhanced modes of transport and communication as well as cooperation from government authorities. </p>
      <h2 class="stats-h2">Attractions in Melaka</h2>
      <p>Melaka, a city that is rich in history and vibrant with culture, has a variety of attractions that bear her unique heritage as well as modern appeal. From historic buildings to lively markets and serene natural sites, Melaka beckons you to discover its past and present. The architecture, culture, and sceneries make the Melaka attraction an ultimate destination in Malaysia.</p>
      <p>The <strong>Stadthuys</strong> is one of the oldest remaining Dutch buildings in the East even though it served as a Dutch administrative centre a long time ago. It is now a historical museum that talks about Melaka’s colonial era through well-preserved Dutch architecture.</p>
      <p><strong>Jonker Street</strong> which is also referred to as Jonker Walk is well known because of its night markets that are always full of life. It is situated along Chinatown heart of Melaka where there are shops selling antiques, artisanal products and indigenous dishes. A market booms by night on this street with food stalls and activities alike.</p>
      <p>A showcase of perpetuated luxury lifestyles among the Straits Chinese originally called Peranakan is displayed at <strong>Melaka Baba House</strong> which belongs to the heritage museum. With its artefacts on diverse items including traditional furniture for the Peranakan community or artworks done in their distinctive style – it takes people into an elaborate examination of how these two cultures have intertwined each other indefinitely for centuries now.</p>
      <p>Portuguese Early 16th-century fortress <strong>A Famosa</strong> is among Asia’s oldest remaining European architectures. The ruins of the fort and Porta de Santiago gate provide breathtaking views of the neighbouring environment.</p>
      <p><strong>Melaka River Cruise</strong>, as a distinctive method of exploring the city, offers boat rides historical structures, traditional houses, and modern murals line its banks while mesmerizing nocturnal river trips help one to experience great moods in their minds.</p>
      <p>The <strong>Maritime Museum</strong> located in a replica of a Portuguese ship called Flor de la Mar displays maritime artefacts and provides an account of Melaka’s maritime history; this enables visitors to obtain a fuller perspective on maritime pasts from that area.</p>
      <p><strong>Melaka Botanical Garden</strong> also serves as an escape from all these bustling activities through its variety of plant species, walking trails and tranquil surroundings. It is where one can relax or take nature walks unlike any other place within this town which is always bustling with life.</p>
      <h2 class="stats-h2">FAQ – Frequently Asked Questions About Melaka</h2>
      <ol>
        <li>What is the best time to visit Melaka?</li><p>Melaka is best visited during the dry season, which lasts from March to October. The weather is usually nicer during these months, with less rainfall and more sunshine; so it’s ideal for touring the historical sites of this city as well as its outdoor attractions.</p>
        <li>How do I get to Melaka?</li><p>It’s easy to go to Melaka by car, bus or flight. If you are taking a road trip from Kuala Lumpur, it will take you approximately two hours on the North-South Expressway (NSE). Major cities like Kuala Lumpur and Singapore have constant bus services operating to Melaka. Alternatively, go for a flight landing at Melaka International Airport (MKZ).</p>
        <li>What are the must-visit attractions in Melaka?</li><p>Be sure to visit the Stadthuys, Jonker Street, St Paul’s Hill and the Melaka Sultanate Palace Museum. Also visit The Melaka Baba House for a journey through Peranakan culture. An exciting night cruise along the river would be remarkable as well!</p>
        <li>Where can I find the best local food in Melaka?</li><p>Melaka has a lot to offer, with its many delicious types of local dishes like Chicken Rice Balls, Satay Celup and Nyonya Laksa. On this street, you can find a variety of street food and traditional dishes in Jonker Street. For a more lavish dining experience, why not try one of the local Peranakan restaurants for authentic Baba-Nyonya cuisines.</p>
        <li>Is Melaka a good place for property investment?</li><p>No doubt that due to its growing tourism sector as well as rich cultural heritage, Melaka appeals to property investors. From modern apartments to beautiful vintage homes; there are several types of <a href="${process.env.REACT_APP_WEBSITE}/properties-for-sale/melaka">properties available for sale</a> in the city. The reasonable cost of real estate coupled with high rental yields makes Melaka an attractive option for both investors and homebuyers alike.</p>
      </ol>
      <h2 class="stats-h2">Overall about Melaka</h2>
      <p>In conclusion, Melaka represents a distinct investment destination where historical allure blends flawlessly with up-to-date possibilities. The city provides an excellent opportunity for investors who are looking for something that combines their love for culture and wish for good returns, owing to its rich history, increasing infrastructure support and vibrant real estate market.</p>
      <p>Real estate is a promising avenue for investors interested in tapping into such possibilities as the ongoing development and relevance of Melaka.</p>`,
    Kedah: "",
    "Negeri Sembilan": "",
    Kelantan: "",
    Pahang: "",
    Labuan: "",
  },
  types: {
    "1-sty Terrace/Link House": require("../assets/landing/1StoreyTerrace.jpg"),
    "1.5-sty Terrace/Link House": require("../assets/landing/1-5storeyTerrace.jpg"),
    "2-sty Superlink House": require("../assets/landing/2storeySuperLink.jpg"),
    "2-sty Terrace/Link House": require("../assets/landing/2StoreyTerrace.jpg"),
    "2.5-sty Terrace/Link House": require("../assets/landing/2-5storeyTerrace.jpg"),
    "3 Storey Semi-detached Homes": require("../assets/landing/3storeySemiD.jpg"),
    "3-sty Superlink House": require("../assets/landing/3storeySuperLink.jpg"),
    "3-sty Terrace/Link House": require("../assets/landing/3storeyTerrace.jpg"),
    "3.5-sty Terrace/Link House": require("../assets/landing/3-5storeyTerrace.jpg"),
    "4-sty Terrace/Link House": require("../assets/landing/4storeyTerrace.jpg"),
    "4.5-sty Terrace/Link House": require("../assets/landing/4-5storeyTerrace.jpg"),
    "Agricultural Land": require("../assets/landing/agricultureLand.jpg"),
    Apartment: require("../assets/landing/apartment.jpg"),
    Bungalow: require("../assets/landing/bungalow.jpg"),
    "Bungalow Land": require("../assets/landing/bungalowLand.jpg"),
    "Business Center": require("../assets/landing/businessCenter.jpg"),
    "Cluster House": require("../assets/landing/clusterHouse.jpg"),
    "Commercial Bumgalow": require("../assets/landing/commercialBungalow.jpg"),
    "Commercial Land": require("../assets/landing/commercialLand.jpg"),
    "Commercial Semi-D": require("../assets/landing/commercialSemiD.jpg"),
    Condominium: require("../assets/landing/condo.jpg"),
    "Designer Suites": require("../assets/landing/designerSuite.jpg"),
    "Detached Factory": require("../assets/landing/detachedFactory.jpg"),
    Factory: require("../assets/landing/factory.jpg"),
    Flat: require("../assets/landing/flat.jpg"),
    "Hotel/Resort": require("../assets/landing/hotelResort.jpg"),
    "Industrial Land": require("../assets/landing/industrialLand.jpg"),
    "Light Industrial": require("../assets/landing/lightIndustrial.jpg"),
    "Link Factory": require("../assets/landing/linkFactory.jpg"),
    "Luxury Suites": require("../assets/landing/luxurySuite.jpg"),
    Office: require("../assets/landing/office.jpg"),
    "Residential Land": require("../assets/landing/residentialLand.jpg"),
    "Retail Office": require("../assets/landing/retailOffice.jpg"),
    "Retail Space": require("../assets/landing/retailSpace.jpg"),
    SOFO: require("../assets/landing/sofo.jpg"),
    SOVO: require("../assets/landing/sovo.jpg"),
    SOVO: require("../assets/landing/sovo.jpg"),
    "Semi-D Factory": require("../assets/landing/semiDFactory.jpg"),
    "Semi-detached House": require("../assets/landing/semiDHouse.jpg"),
    "Serviced Residence": require("../assets/landing/servicedResidence.jpg"),
    Shop: require("../assets/landing/shop.jpg"),
    "Shop Office": require("../assets/landing/shopOffice.jpg"),
    Suites: require("../assets/landing/luxurySuite.jpg"),
    Townhouse: require("../assets/landing/townHouse.jpg"),
    Villa: require("../assets/landing/villa.jpg"),
    Warehouse: require("../assets/landing/warehouse.jpg"),
  },
  landing: {
    "Wilayah Persekutuan Kuala Lumpur": require("../assets/landmark/landmark_kualalumpur.jpg"),
    Perak: require("../assets/landmark/landmark_perak.jpg"),
    Perlis: require("../assets/landmark/landmark_perlis.jpg"),
    Melaka: require("../assets/landmark/landmark_melaka.jpg"),
    Kedah: require("../assets/landmark/landmark_kedah.jpg"),
    "Negeri Sembilan": require("../assets/landmark/landmark_negerisembilan.jpg"),
    Johor: require("../assets/landmark/landmark_johor.jpg"),
    Kelantan: require("../assets/landmark/landmark_kelantan.jpg"),
    Pahang: require("../assets/landmark/landmark_pahang.jpg"),
    "Wilayah Persekutuan Labuan": require("../assets/landmark/landmark_labuan.jpg"),
    Selangor: require("../assets/landmark/landmark_selangor.jpg"),
    Terengganu: require("../assets/landmark/landmark_terengganu.jpg"),
    "Wilayah Persekutuan Putrajaya": require("../assets/landmark/landmark_putrajaya.jpg"),
    Sabah: require("../assets/landmark/landmark_sabah.jpg"),
    Sarawak: require("../assets/landmark/landmark_sarawak.jpg"),
    Penang: require("../assets/landmark/landmark_penang.jpg"),
  },
};

export default STATIC;
